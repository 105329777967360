import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicTextArea from "../../../components/SharedComponents/BasicTextArea/BasicTextArea";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import "./LicensorDetails.scss";
import TableComponent from "../../SharedComponents/Table";
import { AddressesConfig, mock_akas_list } from "./Config";
import messages from '../../../Common/Messages.json';
import { validateCharNumOnly } from "../../../Common/Helper";

class LicensorDetails extends Component {
  constructor() {
    super();
    this.state = {
      postInitiated: this.props?.postInitiated ? this.props?.postInitiated : false,
      licensorData: [],
      showAddresses: false,
      address_history_list: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.licensorData !== state.licensorData) {
      return {
        licensorData: props.licensorData,
        address_history_list: props.address_history_list
      }
    }
    return null
  }
  handleshowAddresses = () => {
    this.setState({ showAddresses: !this.state.showAddresses })
  }

  removeAkaCallback = (akaIndex) => {
    let allDetails = this.state.licensorData
    allDetails.licensor_akas.splice(akaIndex, 1)
    this.setState({ licensorData: allDetails })
  }

  addAkaCallback = (akaIndex) => {
    let allDetails = this.state.licensorData
    allDetails.licensor_akas.push(JSON.parse(JSON.stringify({ ...mock_akas_list })))
    this.setState({ licensorData: allDetails })
  }

  GenerateCleranceDetails = () => { };
  render() {
    return (
      <>
        <MDBContainer fluid className="ModalContainer LicensorDetailContainer" disabled={this.props?.disabled}>
          <MDBRow>
            <MDBCol md={3}>
              <BasicTextField
                label="Licensor Name"
                placeholder="Licensor Name"
                id="additionalMaterial"
                isMandatory={true}
                showMandatory={this.state.postInitiated || false}
                value={this.state.licensorData?.licensor_name}
                onChange={(e) => this.props?.handleGeneralEdit("licensor_name", e.target.value)}
                mandatoryWarning={messages.mandatoryWarning}
              />
            </MDBCol>
            <MDBCol md={4} className="width-re">
              {
                this.state.licensorData?.licensor_akas?.map((aka, index) =>
                  <MDBRow>
                    <MDBCol md={9}>
                      <BasicTextField
                        label={index > 0 ? "" : "AKA(s)"}
                        placeholder={"AKA"}
                        value={aka.aka || null}
                        onChange={(e) => this.props?.onAkaChange(index, e.target.value)}
                      />
                    </MDBCol>
                    <MDBCol md={3} className='flex justify-content-between'>
                      {(this.state.licensorData?.licensor_akas?.length > 1) &&
                        <MDBIcon icon="minus-circle" className={index === 0 ? "plus-height float-right" : "float-right"} onClick={() => this.removeAkaCallback(index)} /> 
                      }
                      {this.state.licensorData?.licensor_akas?.length - 1 === index && aka.aka != null &&
                        <MDBIcon icon="plus-circle" className={index === 0 ? "plus-height float-right" : "float-right"} onClick={() => this.addAkaCallback(index)} /> 
                      }
                    </MDBCol>
                  </MDBRow>
                )}
            </MDBCol>
            <MDBCol md={2} className="height-align width-check">
              <BasicCheckbox
                id="is_provides_own_license"
                label={"Provides Own License"}
                checked={this.state.licensorData?.is_provides_own_license ? true : false}
                onChange={(e) => this.props?.handleCheck(e, "is_provides_own_license")}
              />
              <BasicCheckbox
                id="is_reliance_letter_receiver"
                label={"Reliance Letter Receiver"}
                checked={this.state.licensorData?.is_reliance_letter_receiver ? true : false}
                onChange={(e) => this.props?.handleCheck(e, "is_reliance_letter_receiver")}
              />
              <BasicCheckbox
                id="is_internal_license_with_changes"
                label={"Internal Lic. With Changes"}
                checked={this.state.licensorData?.is_internal_license_with_changes ? true : false}
                onChange={(e) => this.props?.handleCheck(e, "is_internal_license_with_changes")}
              />
            </MDBCol>
            {this.state.licensorData?.licensor_id ?
              <MDBCol md={3} xl={3} >
                    <BasicButton text={"License Entity Info"}
                      onClick={() => this.props.handleShowLicenseEntityInfo()} />
                    <div className="mt-2"><BasicButton text={"Past Addresses"}
                      onClick={() => this.handleshowAddresses()} />
                      </div>
              </MDBCol> : null}
          </MDBRow>
          <br />
          <MDBRow>
            <MDBCol md={3}>
              <BasicTextField
                label="Address"
                placeholder="Address 1"
                id="additionalMaterial"
                //isMandatory={true}
                value={this.state.licensorData?.address_1}
                onChange={(e) => this.props?.handleGeneralEdit("address_1", e.target.value.length > 0 ? e.target.value : null)}
              />
              <BasicTextField
                placeholder="Address 2"
                id="additionalMaterial"
                //isMandatory={true}
                value={this.state.licensorData?.address_2}
                onChange={(e) => this.props?.handleGeneralEdit("address_2", e.target.value.length > 0 ? e.target.value : null)} />
            </MDBCol>
            <MDBCol md={3}>
              <MDBRow>
                <MDBCol md={12}>
                  <BasicTextField
                    label="City"
                    placeholder="City"
                    id="additionalMaterial"
                    //isMandatory={true}
                    value={this.state.licensorData?.city}
                    onChange={(e) => this.props?.handleGeneralEdit("city", e.target.value.length > 0 ? e.target.value : null)} />
                </MDBCol>
                <MDBCol md={6}>
                  {this.state.licensorData?.country === 'United States' ?
                    <SelectField
                      label="State"
                      placeHolderText="State"
                      value={this.state.licensorData?.state ? this.props?.stateList?.find(item => item?.label === this.state.licensorData?.state)?.value : null}
                      options={this.props?.stateList}
                      onChange={(e, item) => this.props?.handleGeneralEdit("state", item?.props?.children)}
                    /> :
                    <BasicTextField
                      placeholder="State"
                      label="State"
                      value={this.state.licensorData?.state}
                      onChange={(e) => this.props?.handleGeneralEdit("state", e.target.value)}
                    />
                  }
                </MDBCol>
                <MDBCol md={6}>
                  <BasicTextField
                    label="Zip Code"
                    placeholder="Zip Code"
                    id="additionalMaterial"
                    //isMandatory={true}
                    value={this.state.licensorData?.zip}
                    onChange={(e) => this.props?.handleGeneralEdit("zip", e.target.value.length > 0 ? e.target.value : null)}
                    // fieldValid={(this.state.licensorData?.zip) ? (!validateCharNumOnly(this.state.licensorData?.zip) ? true : false) : false}
                    // inValidInput={messages.invalidInput}
                    // limit={this.props?.countryList?.find(item => item?.value == this.state.licensorData?.country_id)?.label !== 'United States' ? 10 : 5}
                    // limitWarning={this.props?.countryList?.find(item => item?.value == this.state.licensorData?.country_id)?.label !== 'United States' ? messages.addressLimitIntl : messages.addressLimitUs}
                  />
                </MDBCol>
                <MDBCol md={12}>
                  <SelectField
                    label="Country"
                    placeHolderText={"- Country -"}
                    value={this.state.licensorData?.country_id}
                    options={this.props?.countryList}
                    onChange={(e, item) => {
                      this.props?.handleGeneralEdit("country_id", e.target.value)
                      this.props?.handleGeneralEdit("country", item?.props?.children)
                      this.props?.handleGeneralEdit("state", null)
                    }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol md={3}>
              <BasicTextArea
                label="Notes"
                value={this.state.licensorData?.notes}
                onChange={(e) => this.props?.handleGeneralEdit("notes", e.target.value.length > 0 ? e.target.value : null)} />
            </MDBCol>
            <MDBCol md={3}>
              <BasicTextArea
                label="Warning Notes"
                value={this.state.licensorData?.warning_notes}
                onChange={(e) => this.props?.handleGeneralEdit("warning_notes", e.target.value.length > 0 ? e.target.value : null)} />
            </MDBCol>
          </MDBRow>
          <br />
          <MDBRow>
            {this.state.showAddresses ?
              <TableComponent
                list={this.state.address_history_list || []}
                config={AddressesConfig}
              />
              : ""}
          </MDBRow>
          <br />
        </MDBContainer>
      </>
    );
  }
}

export default LicensorDetails;
