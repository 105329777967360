import React from 'react';
import TableComponent from '../../SharedComponents/Table';
import TablePagination from '@material-ui/core/TablePagination';
import { clearRecordConfig } from './config';
import { withUserContext } from '../../../contexts/UserContext';
import ClearTrackServices from "../../../services/service";
import * as Constants from "../../../constants/constants";
import './EditPayee.scss';
import { updateTableSortConfigObject, handlePaginationList, tableSortList } from '../../../Common/TableHelper';


class ClearanceRecord extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config: JSON.parse(JSON.stringify({ ...clearRecordConfig })),
            list: [],
            allData:[],
            isLoading:false,
            sortBy: "Value",
            isSortByAsc: true,
            sortCount: 0,
            page:0,
            rowsPerPage:20,
        }
    }

    componentDidUpdate(prevProps,prevState) {
        if(this.state.allData?.length > 0 && prevProps?.usingOnly !== this.props?.usingOnly) {
            this.setState({page:0, list : this.props?.usingOnly ? handlePaginationList(0, this.state.rowsPerPage, this.state.allData?.filter(item => item?.status === 'using'))  : handlePaginationList(0, this.state.rowsPerPage, this.state.allData)})
        }
    }

    componentDidMount = () => {
        this.setState({isLoading:true})
        ClearTrackServices.getData(
            Constants.ClearTrackServiceBaseUrl +
            `/clearanceRecordList?entity=PAYEE&entity_id=${this.props?.id}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let payeeData = [];
                payeeData = res?.data?.map( item => {
                    let newItem = {}
                    newItem.program = item?.show_name
                    newItem.season = item?.season_name
                    newItem.episode = item?.episode_title
                    newItem.composition = item?.song_title && item?.performer_name ? `${item?.song_title} by ${item?.performer_name}` :null
                    newItem.rights = item?.rights
                    newItem.fee = item?.fee ? `$${item?.fee}` : null
                    newItem.clearance_id = item?.clearance_id
                    newItem.show_season_id = item?.show_season_id
                    newItem.show_season_episode_id = item?.show_season_episode_id
                    newItem.season_id = item?.season_id
                    newItem.status = item?.status
                    return newItem
                })
                this.setState({
                    list: handlePaginationList(0, this.state.rowsPerPage, payeeData), isLoading: false, allData:payeeData, page:0
                });
                this.handleTableColumnClick("Program");
            })
            .catch((err) => {
                this.setState({ isLoading: false ,list : [], allData:[]});
            });
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }
    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(clearRecordConfig, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            this.setState({
                sortBy: colName,
                renderList: tableSortList("Name", this.getSortNode(colName), this.state.list, this.state.isSortByAsc)
            });
        })
    }

    navigationCallback = (obj, node) => {
        if (obj?.show_season_id && node === 'show_season') {
          window.open(`/showseason/${obj.show_season_id}`, '_blank');
        } else if (obj?.show_season_episode_id && node === 'episode_title') {
          window.open(`/episodeAndClearance/ss/${obj.show_season_id}/ep/${obj.show_season_episode_id}`,'_blank');
        }
      }

    getSortNode = (col) => {
        switch (col) {
            case "Program": return "program";
            case "Season": return "season";
            case "Episode": return "episode";
            case "Compisition": return "composition";
            case "Rights": return "rights";
            case "Fee": return "fee";
            case "Status": return "status";
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
            list: handlePaginationList(newPage, this.state.rowsPerPage, this.state.allData)
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
            list: handlePaginationList(0, parseInt(event.target.value, 10), this.state.allData)
        });
    }

    render() {
        return (
            <div className='payeeEditCLRecordTable'>
                <TableComponent
                    list={this.state.list}
                    config={clearRecordConfig}
                    isLoading={this.state.isLoading}
                    stickyHeader={true}
                    sortCallback={this.handleTableColumnClick}
                    hyperLinkNavigationCallback={this.navigationCallback}
                />
                <TablePagination
                    component="div"
                    count={this.state.allData ? this.state.allData?.length : 0}
                    rowsPerPageOptions={[20,30, 50, 100]}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    rowsPerPage={this.state.rowsPerPage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </div>
        )
    }
}

export default withUserContext(ClearanceRecord);
