import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../../SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../../SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../../SharedComponents/BasicLabel/BasicLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicTextArea from "../../../SharedComponents/BasicTextArea/BasicTextArea";
import SelectField from "../../../SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../../SharedComponents/SearchSelectField"
import "./NewMaintitleClearenceRecord.scss";
import { withUserContext } from '../../../../contexts/UserContext';
import * as Constants from '../../../../constants/constants';
import ClearTrackService from '../../../../services/service';
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {  initialMaintitleClearanceRecord, mockSong2 } from "./config";
import messages from '../../../../Common/Messages.json';
import Popover from '@material-ui/core/Popover';
import axios from 'axios';
import {validateNumbersOnly} from "../../../../Common/Helper"
import { clearanceRecordPermissionsConfig } from '../../../EpisodeAndClearence/EpisodeAndClearenceDetails/Config';
class NewMainTitleClearanceRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postInitiated: false,
            songOptions: [],
            mainTitleClearanceData: this.formatMainTitleClearanceDetails(JSON.parse(JSON.stringify({ ...initialMaintitleClearanceRecord }))),
            useOptions: [],
            isLoading: false,
            fieldChanged: false,
            isPosting: false,
            performerOptions: [],
            performerList: [],
            songUse: [],
            division_id: null,
            isFetchingMainTitleDetails: false,
            isPerformerFetching: [],
            isSongFetching: [],
            searchCancelToken: null,
            deletedSongs: [],
            existingSongList: [],
            customNotes:null,
        };
    };

    formatMainTitleClearanceDetails = (response) => {
        if (response.songs_list.length === 0) {
            response.songs_list.push(JSON.parse(JSON.stringify({ ...mockSong2 })))
            return response;
        } else {
            let newSongList = response?.songs_list?.map(item => {
                let newItem = { ...item }
                newItem.selected_song = {
                    'value': item?.song_id,
                    'text': `${item?.song_title}${item?.writer?.length > 0 ? '('+item?.writer?.map(ele => ele?.writer_name ? ele?.writer_name : ''+ele?.affiliation_name ? '-'+ele?.affiliation_name : '')+')' : ''}` 
                }
                newItem.selected_performer = {
                    'value': item?.performer_id,
                    'text': item?.performer_name
                }
                newItem.min = item?.duration ? item?.duration?.split(":")[0] : null
                newItem.sec= item?.duration ? item?.duration?.split(":")[1] : null
                delete newItem.duration
                return newItem
            })
            response.songs_list = newSongList
            return response
        }
    }

    componentDidMount() {
        this.getEntity("useOptions", "MUSIC_USES", "");
        if (this.props.mode === "New") {
            this.handleChange("guaranteed_eps", this.props?.showSeasonDetails?.proj_eps)
        }
        if (this.props.mode === "Edit") {
            this.getMainTitleRecord(this.props?.mainTitleId)
        } else  {
            this.getSongs(' ',0)
        }
    }

    getEntity = (entity, entitytype, searchString) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = [];
                if (entitytype === 'MUSIC_USES') {
                    formattedList = response?.data?.map(item => ({ value: item.music_uses_id, label: item.abbreviation }));
                }
                this.setState({ [entity]: formattedList });
            },
                (err) => {
                    console.log("Error in fetching entities:", err)
                })
    }

    getPerformer = (song_id, index) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/songDetails?song_id=${song_id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let songDoNotUse = response?.data[0]?.song_is_do_not_use
                let formattedList = response.data[0]?.performers_list.length > 0 ?
                    response.data[0]?.performers_list?.map(item => ({ value: item.performer_id, text: item.performer_name })) : [];
                if (formattedList.length > 0) {
                    this.handleShowChange("performer", formattedList[0], index)
                } else {
                    this.handleShowChange("performer", null, index)
                }
                let performer = [...this.state.performerList]
                performer[index] = formattedList
                let songUseArr = [...this.state.songUse]
                songUseArr[index] = songDoNotUse
                this.setState({ performerList: performer, songUse: songUseArr });
            },
                (err) => {
                    this.handleSongDataChange("performer", null, index)
                    console.log("Error in fetching performer Details:", err)
                })
    }

    getPerformerDetails = (searchString, index) => {
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        let flags = [...this.state.isPerformerFetching]
        flags[index] = true
        this.setState({ isPerformerFetching: flags })
        ClearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=PERFORMER&searchString=${searchString}`, cancelToken, this.props.userContext?.active_tenant?.tenant_id)
            .then((res) => {
                let formattedList = res?.data?.map(item => ({ value: item?.performer_id, text: item?.performer_name }));
                if (this.state.performerList[index]?.length > 0) {
                    this.state.performerList[index].map((item) => {
                        formattedList.unshift(item)
                    })
                }
                let performer = [...this.state.performerOptions]
                performer[index] = formattedList
                let flags = [...this.state.isPerformerFetching]
                flags[index] = false
                this.setState({ isPerformerFetching: flags, performerOptions: performer })
            })

            .catch((err) => {
                let flags = [...this.state.isPerformerFetching]
                flags[index] = false
                this.setState({ isPerformerFetching: flags })
                console.log("error")
            });
    };

    getSongs = (value, index) => {
        let searchObj = {
            'data_type': 'SONGS',
            'search_string':value,
        }
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        //if (value) {
            let flags = [...this.state.isSongFetching]
            flags[index] = true
            this.setState({ isSongFetching: flags })
            ClearTrackService.getDataParamsWithCancel(Constants.ClearTrackServiceBaseUrl + '/globalSearch', searchObj, this.props.userContext?.active_tenant?.tenant_id, null, cancelToken) 
                .then(response => {
                    let flags = [...this.state.isSongFetching]
                    flags[index] = false
                    let exsitingSongs = this.state.mainTitleClearanceData?.songs_list?.map(item => item?.song_id)
                    let formattedList = response?.data?.data?.map(item => ({ value: item?.id?.split('_')[1], text: item?.name }))
                    this.setState({
                        isSongFetching: flags,
                        songOptions: formattedList?.filter(item => !exsitingSongs?.includes(item?.value))
                    })
                },
                    (err) => {
                        let flags = [...this.state.isSongFetching]
                        flags[index] = false
                        this.setState({ isSongFetching: flags })
                }
            )
        //}
    }  

    getMainTitleRecord = (main_title_id) => {
        this.setState({ isFetchingMainTitleDetails: true });
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/mainTitle?main_title_id=${main_title_id}`, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                let mainTitleList = response?.data[0]
                let sondDonotArr = mainTitleList?.songs_list?.map(item => item?.is_do_not_use)
                let existingSongs = mainTitleList?.songs_list
                this.setState({
                    mainTitleClearanceData: mainTitleList ? this.formatMainTitleClearanceDetails(mainTitleList) : this.formatMainTitleClearanceDetails(JSON.parse(JSON.stringify({ ...initialMaintitleClearanceRecord }))),
                    isFetchingMainTitleDetails: false,
                    division_id: response?.data[0]?.division_id,
                    songUse: sondDonotArr,
                    existingSongList: existingSongs
                })
                // if (mainTitleList?.show_id) {
                //     this.getSeasons(mainTitleList?.show_id)
                // }
            },
                (err) => {
                    console.log("Error in fetching entities:", err)
                    this.setState({ isFetchingMainTitleDetails: false });
                })
    }



    getStaticData = (tableName, fieldName) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=${tableName}`, null)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                this.setState({ [fieldName]: formattedList, });
            },
                (err) => {
                    console.log("Error in fetching Details:", err)
                })
    }

    handleSubmit = () => {
        this.setState({ postInitiated: true })
        let data = null;
        data = {...this.state.mainTitleClearanceData};
        data.show_id = this.props?.showId || null
        data.season_id = this.props?.seasonId || null
        data.show_season_id = this.props?.show_season_id || null
        if (this.validateFlagError(data)) {
            this.postMainTitle(data);
        }
    }

    validateFlagError = (data) => {
        let canSubmit = false;
        canSubmit = (
            data?.show_id && data?.season_id && data?.show_season_id && data?.show_yr &&
            (data?.guaranteed_eps ? data?.guaranteed_eps <= this.props.episodeLength : true) && (data?.songs_list?.length > 0 && this.validateSongs(data?.songs_list)) && validateNumbersOnly(data?.show_yr))
        return canSubmit;
    }
    validateSongs(songList) {
        let canSubmit = true;
        let i = 0;
        for (i; i < songList?.length; i++) {
            let obj = songList[i];
            if ((!obj.song_id || !obj.song_title || !obj.performer_id || !obj.performer_name || !obj.music_uses_id) ||  ( this.state.useOptions?.find(item => item?.value === obj.music_uses_id)?.label !== 'Custom Use' ? 
            (obj?.sec ? !(obj?.sec < 60 && validateNumbersOnly(obj?.sec)) : false) || (obj?.min ? !(validateNumbersOnly(obj?.min)) : false) : false) ) {
                canSubmit = false;
                break;
            }
        }
        return canSubmit;
    }

    postMainTitle = (postJson) => {
        this.setState({ isPosting: true, postInitiated: true })
        postJson.show_season_id = this.props.show_season_id
        postJson.clearance_rep_id = this.props?.clearance_rep_id || null
        if (this.state.deletedSongs.length > 0) {
            postJson.songs_list = [...postJson.songs_list, ...this.state.deletedSongs]
        }
        for(let i = 0 ; i < postJson.songs_list?.length ; i++) {
            postJson.songs_list[i].duration = `${postJson?.songs_list[i]?.min ? postJson.songs_list[i].min : 0}:${postJson.songs_list[i]?.sec ? postJson.songs_list[i]?.sec : 0}`
            delete postJson.songs_list[i].min
            delete postJson.songs_list[i].sec
        }
        let extensionUrl = '/mainTitle'
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + extensionUrl, postJson, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then((response) => {
                if (!response.data.error) {
                    this.setState({ isPosting: false, postInitiated: false })
                    this.props?.fieldChanged(false, "error");
                    this.props?.notificationComponent(true, "success")
                    setTimeout(()=>{
                        if (this.props?.mode === "New") {
                            this.props?.toggleRefreshMainTitle(response?.data[0]?.main_title_id);
                            this.props.fetchData("getInitialValues", `/showSeason?showSeasonId=${this.props.show_season_id}`, false, "mainTitle");
                        } else {
                            this.props?.toggleRefreshMainTitleLicensor();
                        }
                    },1500)
                }
                else {
                    this.setState({ isPosting: false, postInitiated: false })
                    this.props?.notificationComponent(true, "fail")
                }
            },
                (err) => {
                    this.setState({ isPosting: false, postInitiated: false })
                    this.props?.notificationComponent(true, "fail")
                    console.log("Post agency details error: " + err);
                });
    }

    handleChange = (field, value) => {
        if (field === "is_apply_to_HV") {
            this.setState({
                mainTitleClearanceData: {
                    ...this.state.mainTitleClearanceData,
                    [field]: value ? 1 : 0,
                },
            });
        }
        else {
            this.setState((prevState) => ({
                mainTitleClearanceData: {
                    ...prevState.mainTitleClearanceData,
                    [field]: value,
                },
            }));
        }
        this.props?.fieldChanged(true, "change")
    }

    removeSong(index) {
        let data = null;
        data = {...this.state.mainTitleClearanceData}
        let dataItem = data?.songs_list[index]
        let deletedItem = data?.songs_list[index]
        if (deletedItem?.main_title_song_id) {
            deletedItem.is_delete = 1
            let deletedSongs = [...this.state.deletedSongs, deletedItem]
            this.setState({ deletedSongs: deletedSongs })
            data.is_songs_updated = 1
        }
        data.songs_list.splice(index, 1)
        let existingSong = this.state.existingSongList?.filter(item => item.song_id !== deletedItem?.song_id)
        this.setState({ mainTitleClearanceData: data, existingSongList: existingSong })
        this.props?.fieldChanged(true, "change")
    }

    addSong() {
        let data = null;
        data = {...this.state.mainTitleClearanceData}
        data.songs_list.push(JSON.parse(JSON.stringify({ ...mockSong2 })))
        this.setState({
            mainTitleClearanceData: data
        }, () => {
            this.getSongs(' ',data.length)
        })
        this.props?.fieldChanged(true, "change")
    }

    handleShowChange = (fieldName, value, index) => {
        let data = null;
        data = {...this.state.mainTitleClearanceData}
        if (fieldName === "song") {
            if (value) {
                data.songs_list[index][fieldName + '_id'] = value?.value || null;
                data.songs_list[index][fieldName + '_title'] = value?.text || null;
                data.songs_list[index]['selected_song'] = value || null;
                data.songs_list[index]["performer_id"] = null;
                data.songs_list[index]["performer_name"] = null;
                data.songs_list[index]["selected_performer"] = null;
                data.is_songs_updated = 1
                data.songs_list[index]["songLoader"] = true;
                this.getPerformer(value?.value, index);
            } else {
                data.songs_list[index][fieldName + '_id'] = null;
                data.songs_list[index][fieldName + '_title'] = null;
                data.songs_list[index]['selected_song'] = null;
                data.songs_list[index]["performer_id"] = null;
                data.songs_list[index]["performer_name"] = null;
                data.songs_list[index]["selected_performer"] = null;
                data.songs_list[index]["songLoader"] = false;
                this.setState({ performerOptions: [] })
            }
        }
        else 
        if (fieldName === "min" || fieldName === "sec") {
                data.songs_list[index][fieldName] = value
                data.is_songs_updated = 1
        }
        else if (fieldName === "music_uses_id") {
            data.songs_list[index][fieldName] = value
            data.is_songs_updated = 1
            data.songs_list[index]['custom_use'] = this.state.useOptions?.find(item => item?.value === value)?.label !== 'Custom Use' ? null : data.songs_list[index]['custom_use'] || null
        }
        else if (fieldName === "performer") {
            data.songs_list[index][fieldName + '_id'] = value?.value || null;
            data.songs_list[index][fieldName + '_name'] = value?.text || null;
            data.songs_list[index]['selected_performer'] = value;
            data.songs_list[index]['songLoader'] = false
            data.is_songs_updated = 1
        } else if (fieldName === 'custom_use') {
            data.songs_list[index][fieldName] = value;
            data.songs_list[index]["min"] = 0;
            data.songs_list[index]["sec"] = 0;
            data.songs_list[index]['customPopover'] = null
        }
         else {
            data.songs_list[index][fieldName] = value
        }
        this.setState({ mainTitleClearanceData: data }, () => {
            if(fieldName === 'customPopover') {
                this.setState({customNotes : value ? data.songs_list[index]['custom_use'] : null })
            }
            if (fieldName === 'custom_use') { 
                this.setState({customNotes :  null })
            }
        })
        this.props?.fieldChanged(true, "change")
    }

    render() {
        let editMainTitlePermissions = this.props?.isMainTitle ? this.props?.mainTitlePermissions?.find((per) => clearanceRecordPermissionsConfig.find((item) => item.buttonName == "New CR" && item.permissionGroupName.includes(per.permission_group_name))) : null;
        let data = null;
        let heading = null;
        data = {...this.state.mainTitleClearanceData}
        heading = "For " + data.show_name + "_" + data.season_name
        let isLoading = data;
        let newMode = "For " + this.props?.show_name + "-" + this.props?.season_name
        return (
            <>
                {this.state.isFetchingMainTitleDetails ? (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress size={24} />
                    </div>
                ) : (
                        <MDBContainer fluid className="ModalContainer ClearanceRecordContainer NewClearanceRecordContainer">
                            <div className="between">
                                <div>
                                    {
                                        this.props?.from ? null :
                                            <BasicLabel
                                                text={this.props.mode === "Edit" ? heading : newMode}
                                            />
                                    }
                                </div>
                                <div>
                                    <BasicLabel text={"Clearance Rep"} />
                                    <div className="clearanceRepText">{this.props?.clearance_rep || "-"}</div>
                                </div>
                            </div>
                            {this.state.mainTitleClearanceData?.songs_list?.map((song, index) => (
                                <MDBRow key={"dropdownrow" + index}>
                                    <MDBCol md={4} disabled={this.state.existingSongList[index]?.song_id === song.song_id ? true : false}>
                                        <SearchSelectField
                                            id={`song${index}`}
                                            label={`${index === 0 ? "Song(s)" : ""}`}
                                            placeholder={'- Search -'}
                                            width={'100%'}
                                            searchSelect={true}
                                            isMandatory={true}
                                            loading={this.state.isSongFetching[index]}
                                            multiple={false}
                                            showMandatory={this.state.postInitiated}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            detail_selected={song?.selected_song}
                                            options={this.state.songOptions || []}
                                            valueSelected={(e, newValue) => this.handleShowChange("song", newValue, index)}
                                            searchText={ev => {
                                                // dont fire API if the user delete or not entered anything
                                                if (ev.target.value !== "" && ev.target.value !== null) {
                                                    this.getSongs(ev.target.value, index)
                                                } else {
                                                    let flags = [...this.state.isSongFetching]
                                                    flags[index] = false
                                                    this.setState({ isSongFetching: flags })
                                                }
                                            }}
                                        />
                                        {
                                            this.state.songUse[index] === 1
                                                ?
                                                <span>
                                                    <span class="donot"><i>DO NOT USE song</i> </span>
                                                </span>
                                                : ""
                                        }
                                    </MDBCol>
                                    {/* disabled={(!song.song_id || this.state.existingSongList[index]?.song_id === song.song_id) ? true : false} */}
                                    <MDBCol md={3} id="performer" >                                                      
                                        {song?.songLoader ? <CircularProgress size={15}/> : <SearchSelectField
                                            id={`performer${index}`}
                                            label={`${index === 0 ? "Perfomer" : ""}`}
                                            placeholder={'- Search -'}
                                            isMandatory={true}
                                            showMandatory={this.state.postInitiated}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            options={this.state.performerOptions[index] || []}
                                            searchSelect={true}
                                            detail_selected={song?.selected_performer}
                                            loading={this.state.isPerformerFetching[index]}
                                            width={'100%'}
                                            valueSelected={(e, newValue) => this.handleShowChange("performer", newValue, index)}
                                            searchText={ev => {
                                                if (ev.target.value !== "" && ev.target.value !== null) {
                                                    this.getPerformerDetails(ev.target.value, index)
                                                } else {
                                                    let flags = [...this.state.isPerformerFetching]
                                                    flags[index] = false
                                                    this.setState({ isPerformerFetching: flags })
                                                }
                                            }}
                                        />}
                                    </MDBCol>

                                    {this.state.useOptions?.find(item => item?.value === song.music_uses_id)?.label !== 'Custom Use' ? <MDBCol md={2} className='flex justify-content-between'>
                                        <BasicTextField
                                            label={`${index === 0 ? "Min" : ""}`}
                                            placeholder={"__"}
                                            value={song.min}
                                            fieldValid={song.min ? !validateNumbersOnly(song.min) : false}
                                            inValidInput={"Invalid Min."}
                                            onChange={(e) => this.handleShowChange("min", e.target.value.length > 0 ? e.target.value : null, index)} />
                                        <BasicTextField
                                            value={':'}
                                            label={`${index === 0 ? " " : ""}`}
                                            showAsLabel={true}/>
                                        <BasicTextField
                                            label={`${index === 0 ? "Sec" : ""}`}
                                            placeholder={"__"}
                                            value={song.sec}
                                            fieldValid={song.sec ? !(validateNumbersOnly(song.sec) && song.sec < 60) : false}
                                            inValidInput={"Invalid Sec."}
                                            onChange={(e) => this.handleShowChange("sec", e.target.value.length > 0 ? e.target.value : null, index)} />
                                    </MDBCol> :
                                    <MDBCol>
                                        {index === 0  && <BasicLabel text={'Duration'}/>}
                                        <BasicButton 
                                             onClick={(e) => {
                                                    this.handleShowChange("customPopover", e.currentTarget, index)
                                             }}  
                                             variant="outlined" 
                                             text={"View"} 
                                        />
                                        <Popover
                                            id={index+song?.music_uses_id}
                                            open={ Boolean(song?.customPopover)}
                                            anchorEl={song?.customPopover}
                                            getContentAnchorEl={null}
                                            onClose={() =>  {
                                                    this.handleShowChange("customPopover", null, index)                                               
                                            }}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            PaperProps={{
                                            style: {
                                                width: '30%',
                                                borderRadius: '1px',
                                            }
                                            }}
                                            >
                                            <BasicTextArea
                                                label={""}
                                                rows={5}
                                                value={this.state.customNotes || null }
                                                onChange={(e) =>
                                                    this.setState({customNotes : e.target.value?.length > 0 ? e.target.value : null})
                                                    }
                                                />
                                            <MDBRow className="mt-2">
                                                <MDBCol md={8}>
                                                    <BasicButton 
                                                      text={"Sync Use Only"} 
                                                      onClick={() => {
                                                        let currentString = this.state.customNotes
                                                        currentString = currentString ?  currentString?.includes('SYNC:') ? currentString : 'SYNC: '+currentString : 'SYNC: '
                                                        this.setState({customNotes : currentString})
                                                      }}
                                                    />
                                                </MDBCol>
                                                <MDBCol md={4}>
                                                    <BasicButton 
                                                      text={"OK"} 
                                                      onClick={() => {
                                                        this.handleShowChange("custom_use", this.state.customNotes, index)
                                                      }}
                                                    />
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow className="mt-2">
                                                <MDBCol md={8}>
                                                    <BasicButton 
                                                      text={"Master Use Only"} 
                                                      onClick={() => {
                                                        let currentString = this.state.customNotes
                                                        currentString = currentString ?  currentString?.includes('MASTER:') ? currentString : currentString+'\nMASTER: ' : '\nMASTER: '
                                                        this.setState({customNotes : currentString})
                                                      }}
                                                    />
                                                </MDBCol>
                                                <MDBCol md={4}>
                                                    <BasicButton onClick={() => this.handleShowChange("customPopover", null, index)} variant="outlined" text={"Cancel"} />
                                                </MDBCol>
                                            </MDBRow>
                                            </Popover>
                                    </MDBCol>}
                                    <MDBCol md={2} id="music_use">
                                        <SelectField
                                            label={`${index === 0 ? "Use" : ""}`}
                                            isMandatory={true}
                                            showMandatory={this.state.postInitiated}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            placeHolderText={"- Select - "}
                                            value={song.music_uses_id}
                                            options={this.state.useOptions || []}
                                            onChange={(e) => this.handleShowChange("music_uses_id", e.target.value, index)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={1} className={index === 0 ? "align-end" : "self-center"}>
                                        <div className="add-remove-icons">
                                            <div>
                                                {!(data.songs_list.length === 1)
                                                    ? <RemoveCircleIcon fontSize="small" onClick={() => this.removeSong(index)} />
                                                    : null
                                                }
                                            </div>
                                            <div className="ml5">
                                                {
                                                    data.songs_list.length - 1 === index && song.song_id != null ?
                                                        <AddCircleIcon fontSize="small" onClick={() => this.addSong(index)} />
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </MDBCol>

                                </MDBRow>
                            ))}
                            <MDBRow className="mt-2">
                                <MDBCol md={8}>
                                    <MDBRow>
                                        <MDBCol md={8}>
                                            <BasicTextArea
                                                label={"Main Title Credits"}
                                                value={data.main_title_credits}
                                                onChange={(e) => this.handleChange("main_title_credits", e.target.value)}
                                            />
                                        </MDBCol>
                                        <MDBCol md={4} className={"display-eps"}>
                                            <MDBRow>
                                                <MDBCol md={8}>
                                                    <BasicTextField label={"Guaranteed Eps"}
                                                        value={data.guaranteed_eps || null}
                                                        onChange={(e) => this.handleChange("guaranteed_eps", e.target.value.length > 0 ? e.target.value : null)}
                                                        fieldValid={!(data.guaranteed_eps <= this.props.episodeLength) && data.guaranteed_eps}
                                                        inValidInput={"Guaranteed Eps should be less than equal to total Eps"}
                                                    />
                                                </MDBCol>
                                                <MDBCol md={4}>
                                                    <div className={"episode-text"}>
                                                        <p>({this.props.episodeLength} Total)</p>
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                                <MDBCol md={4}>
                                    <MDBRow>

                                        <MDBCol md={6}>
                                            <BasicTextField label={"Show Yr"}
                                                isMandatory={true}
                                                showMandatory={this.state.postInitiated}
                                                mandatoryWarning={messages.mandatoryWarning}
                                                value={data.show_yr}
                                                onChange={(e) => this.handleChange("show_yr", e.target.value)}
                                                fieldValid={data.show_yr ? !validateNumbersOnly(data.show_yr) : false}
                                                inValidInput={"Please enter number"}
                                            />
                                        </MDBCol>
                                        {<MDBCol className={"mt-2"} md={6}>
                                            <BasicCheckbox
                                                id="Hv"
                                                label={"Apply $ to HV"}
                                                checked={data.is_apply_to_HV}
                                                onChange={(e) => this.handleChange("is_apply_to_HV", e.target.checked)}
                                            />
                                        </MDBCol>}
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                            <br />
                            <MDBRow>
                                <MDBCol md={10}></MDBCol>
                                <MDBCol md={2}>
                                    <MDBRow>
                                        <MDBCol>
                                            <BasicButton text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : this.props?.mode === "Edit" ? "Save" : "Create"} onClick={this.handleSubmit} disabled={this.props?.isMainTitle ? (editMainTitlePermissions?.edit == 1 ? false : true) : false}/>
                                        </MDBCol>
                                        <MDBCol>
                                            <BasicButton onClick={() => this.props?.handleClose()} variant="outlined" text={"Cancel"} />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    )
                }
            </>
        );
    }
}
export default withUserContext(NewMainTitleClearanceRecord);
