export const songLibraryFilter = {
    id: null,
    title: null,
    writer: null,
    performer: null,
    syncLicensor: null,
    masterLicensor: null,
    cueSheetInfo: null,
    doNotUse: null,
    studioOwned: null,
    library: null,
    coa: null,
    containsSample: null,
    publicDomain: null,
    publicDomainGenre: null,
    isArrangement: null,
    promoUse: null,
    show: null,
    season: null,
    showYr: null,
    episodeName: null,
    episode: null,
}

export const songLibraryJson = {
    song_search_text: null,
    writer_search_text: null,
    performer_search_text: null,
    sync_lic_search_ids: null,
    master_lic_search_ids: null,
    cue_info_search_text: null,
    do_not_use_flag: null,
    is_studio_owned_flag: null,
    is_library_flag: null,
    is_coa_flag: null,
    is_contains_sample_flag: null,
    is_pd_flag: null,
    public_domain_genre_id: null,
    is_arrangement_flag: null,
    promo_use_flag: null,
    show_search_text: null,
    show_search_ids: null,
    season_search_ids: null,
    episode_title_search_text: null,
    episode_no_search_text: null,
    order_by_clause: "Show/Season",
    order_by: null,
    page_count: 25,
    page_no: 1
}

export const songLibraryTblConfig = {
    headings: [{
        headingLabel: "Song Title",
        icon: "sort-alpha-down",
        width: "20%"
    },
    {
        headingLabel: "Writer(s)",
        icon: "",
        width: "10%"
    },
    {
        headingLabel: "Performer(s)",
        icon: "",
        width: "20%"
    },
    // {
    //     headingLabel: "Duration",
    //     icon: "",
    //     width: "10%"
    // },
    {
        headingLabel: "Sync Licensor",
        icon: "",
        width: "20%"
    },
    {
        headingLabel: "Library",
        icon: "",
        width: "8%"
    },
    {
        headingLabel: "Do Not Use",
        icon: "",
        width: "10%"
    },
    {
        headingLabel: "PD",
        icon: "",
        width: "8%"
    }],
    dataNodes: ["song_title", "writer_name", "performer_name", "sync_licensor", "is_library", "is_do_not_use", "is_PD"],
    primaryKey: "song_id",
    hyperlinks: ["song_title"],
    nodeFields: [
        {
            column: "is_library",
            icon: "check"
        },
        {
            column: "is_do_not_use",
            icon: "check"
        },
        {
            column: "is_PD",
            icon: "check"
        },
    ],
    //toggleFields: ["is_library", "is_do_not_use", "is_PD"],
    actions: []
}

export const songLibraryDataConfig = [
    {
        id: 1,
        SongTitle: "SongTitle",
        Writer: "Writer",
        Performer: "Performer",
        Duration: "Duration",
        SyncLicensor: "SyncLicensor",
        Library: true,
        DoNotUse: false,
        PD: true
    },
    {
        id: 2,
        SongTitle: "SongTitle",
        Writer: "Writer",
        Performer: "Performer",
        Duration: "Duration",
        SyncLicensor: "SyncLicensor",
        Library: false,
        DoNotUse: true,
        PD: true
    },
    {
        id: 3,
        SongTitle: "SongTitle",
        Writer: "Writer",
        Performer: "Performer",
        Duration: "Duration",
        SyncLicensor: "SyncLicensor",
        Library: false,
        DoNotUse: true,
        PD: true
    },
    {
        id: 4,
        SongTitle: "SongTitle",
        Writer: "Writer",
        Performer: "Performer",
        Duration: "Duration",
        SyncLicensor: "SyncLicensor",
        Library: false,
        DoNotUse: false,
        PD: true
    },
    {
        id: 5,
        SongTitle: "SongTitle",
        Writer: "Writer",
        Performer: "Performer",
        Duration: "Duration",
        SyncLicensor: "SyncLicensor",
        Library: true,
        DoNotUse: true,
        PD: false
    },
]

export const filterConfig = {
    licensor: { id: 'licensor_id', name: 'licensor_name'},
    show: { id: 'show_id', name: 'show_name'},
    season: { id: 'season_id', name: 'season_name' },
    musictype: {id: 'public_domain_genre_id', name: 'public_domain_genre'}
}

export const options = [
    {
        label: "Select",
        value: null
    },
    {
        label: "True",
        value: 1
    },
    {
        label: "False",
        value: 2
    }
]