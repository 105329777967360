import React from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardBody, MDBCardText } from 'mdbreact';
import { intialPilotBudgetDetails, pilotBudgetConfig, pilotList, pilotBudgetConfig2, pilotList2, intialPilotHeaderDetails } from './Config';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import BasicCheckbox from "../../SharedComponents/BasicCheckbox/BasicCheckbox";
import Radio from "@mui/material/Radio";
import TableComponent from '../../SharedComponents/Table';
import "./Budget.scss";
import Popover from '@material-ui/core/Popover';
import PilotModal from './PilotModal';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';
import { getFormattedDate, validateNumbersOnly } from '../../../Common/Helper';
import { CircularProgress } from "@material-ui/core";

let canEditBudget = false, canEditBudgetDetails = false, load = {};
class Pilot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pilotDetails: null,
            pilotBudgetHeaderDetails: JSON.parse(JSON.stringify({ ...intialPilotHeaderDetails })),
            renderList: [],
            config: pilotBudgetConfig,
            config2: pilotBudgetConfig2,
            setModal: null,
            summaryBudget: [],
            showDetailsOptions: null,
            isSubmit: false,
            isLoading: false,
            isPostInitiated: false,
            totalEfc: 0,
            totalEstCost: 0,
            totalLockedCost: 0,
            mainPilotDetails: {},
            pdfPayload: {},
            excelPayload: {},
            isPdfPosting: false,
            isExcelPosting: false,
            errorFlag: false,
            errorMsg: null,
            setPdfModal: false,
            setGeneratePdfPopup: null,
            setGenerateExcelPopup: null,
            clearanceTabList: null,
            isEmailLoader: false
        };
    }
    // componentDidUpdate(prevState) {
    //     // Typical usage (don't forget to compare props):
    //     if (this.state.pilotDetails !== prevState.pilotDetails) {
    //         this.getPermissions(this.props.permissionArr);
    //     }
    // }
    componentDidMount = () => {
        this.fetchData("getInitialValues", `/showSeason?showSeasonId=${this.props.show_season_id}`);
        this.getPermissions(this.props.permissionArr);
        this.getDetailsOptions("showDetailsOptions", "BUDGET_DETAIL_CODE", "null");
    }

    getPilotBudgetDetails = (loader = true) => {
        this.setState({ isLoading: loader })
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonPilotBudgetDetails?show_season_id=${this.props?.show_season_id}&apply_to=${this.state.mainPilotDetails?.customize_title ? this.state.mainPilotDetails?.customize_title : 'PILOT'}`, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                let pilotDetails = response.data[0]
                let pilotBudgetHeaderRecord = this.getPilotBudgetHeaderDetails(pilotDetails);
                this.setState({ renderList: pilotDetails.pilot_budget_details, pilotDetails: pilotDetails, pilotBudgetHeaderDetails: pilotBudgetHeaderRecord, isLoading: false })
                let totalEfc = this.state.renderList.reduce((accumulator, object) => {
                    return accumulator + object.efc;
                }, 0);
                let totalEstCost = this.state.renderList.reduce((accumulator, object) => {
                    return accumulator + object.est_cost;
                }, 0);
                let totalLockedCost = this.state.renderList.reduce((accumulator, object) => {
                    return accumulator + object.locked_cost;
                }, 0);
                let summaryBudget = pilotDetails?.summary?.map(item => {
                    let newItem = {
                        "cost_center_name": item?.cost_center_name,
                        "description": item?.description,
                        "est_cost": item?.total_est_cost,
                        "locked_cost": item?.locked_cost,
                        "efc": item?.efc
                    }
                    return newItem
                })
                this.setState({
                    totalEfc: totalEfc, totalEstCost: totalEstCost, isSubmit: false,
                    totalLockedCost: totalLockedCost, summaryBudget: summaryBudget
                }, () => { this.fetchData("pilotCBDetailsHeader", `/showSeasonPilotClearanceBudget?show_season_id=${this.props.show_season_id}`); })
                console.log("item", this.state.totalEfc);
            })
            .catch((err) => {
                this.setState({ isLoading: false });
            });


    }

    getPilotBudgetHeaderDetails = (response) => {
        let headerDetails = { ...this.state.pilotDetails }
        headerDetails.show_season_pilot_budget_header_id = response.show_season_pilot_budget_header_id;
        headerDetails.script_date = response.script_date;
        headerDetails.preparation_date = response.preparation_date;
        headerDetails.comments = response.comments;
        headerDetails.show_season_id = response.show_season_id;
        headerDetails.season_id = response.season_id;
        headerDetails.show_id = response.show_id;

        return headerDetails;
    }

    getDetailsOptions = (entity, entitytype, searchString) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`, this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then((res) => {
                let formattedList = res?.data?.map(item => ({ value: item.budget_detail_code_id, label: `${item?.detail_code}-${item?.description}` }));
                let config = { ...this.state.config }
                config.headings[0].selectOptions = formattedList
                this.setState({ showDetailsOptions: formattedList });
            })
            .catch((err) => {
                console.log("error")
            });
    };

    handleInlineEdits = (id, dataItem) => {
        let allDetails = [...this.state.renderList]
        this.setState({
            renderList: allDetails.map((item) => {
                let newItem = {...item}
                if (item.show_season_pilot_budget_details_id == id)
                    newItem['editing'] = true
                return newItem
            })
        })
    }

    postPilotBudgetDetails = (showPilotBudgetDetails, postFlag) => {
        this.setState({ isSubmit: postFlag });
        let Data = { ...showPilotBudgetDetails }
        Data.detail = this.state.showDetailsOptions.find(data => data.value === Data.detail)?.label
        Data.detail_id = this.state.showDetailsOptions.find(data => data.value === Data.detail_id)?.value
        Data.show_season_id = this.props?.show_season_id,
            Data.season_id = this.props?.initialRecordData?.season_id,
            Data.show_id = this.props?.initialRecordData?.show_id,
            Data.apply_to = this.state.mainPilotDetails?.customize_title ? this.state.mainPilotDetails?.customize_title : "PILOT",
            Data.show_season_pilot_budget_header_id = this.state.pilotDetails?.show_season_pilot_budget_header_id
        ClearTrackService.postDataParams(
            Constants.ClearTrackServiceBaseUrl + `/showSeasonPilotBudgetDetails`,
            Data,
            this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id
        ).then(
            (response) => {
                if (response.data.error) {
                    this.setState({ isSubmit: false });
                    this.props?.notificationComponent("error")
                } else {
                    // this.setState({
                    //     isSubmit: false,
                    // });
                    if (showPilotBudgetDetails?.editing) {
                        showPilotBudgetDetails.editing = false;
                    }
                    this.props?.notificationComponent("success")
                    this.getPilotBudgetDetails(false);
                    this.setState({ errorFlag: false, errorMsg: null });
                }
            },
            (err) => {
                this.setState({
                    isSubmit: false,
                });
                this.props?.notificationComponent("error")
                console.log("Post showProgramDetails Details error: " + err);
            }
        );

    };

    saveInlineEdits = (data, index, pkValue, dataItem) => {
        this.setState({ errorFlag: false, errorMsg: null });
        let showPilotBudget = [...data];
        let showPilotBudgetDetails = [...this.state.renderList];
        //showPilotBudgetDetails[index].detail = showPilotBudget[index]?.detail_id || null;
        showPilotBudgetDetails[index].budget_detail_code_id = showPilotBudget[index]?.budget_detail_code_id || null;
        showPilotBudgetDetails[index].description = showPilotBudget[index]?.description || null;
        showPilotBudgetDetails[index].secondary_description = showPilotBudget[index]?.secondary_description || null;
        showPilotBudgetDetails[index].est_cost = showPilotBudget[index]?.est_cost || null;
        showPilotBudgetDetails[index].efc = showPilotBudget[index]?.efc || null;
        showPilotBudgetDetails[index].locked_cost = showPilotBudget[index]?.locked_cost || null;
        if ((showPilotBudgetDetails[index]["budget_detail_code_id"] !== null && showPilotBudgetDetails[index]["budget_detail_code_id"] !== undefined) && (showPilotBudgetDetails[index]["est_cost"] !== null && showPilotBudgetDetails[index]["est_cost"] !== undefined && validateNumbersOnly(showPilotBudgetDetails[index]["est_cost"]))) {
            if (showPilotBudgetDetails[index]["efc"] === null || showPilotBudgetDetails[index]["efc"] === undefined || (showPilotBudgetDetails[index]["efc"] !== null && showPilotBudgetDetails[index]["efc"] !== undefined && validateNumbersOnly(showPilotBudgetDetails[index]["efc"]))) {
                if (showPilotBudgetDetails[index]["locked_cost"] === null || showPilotBudgetDetails[index]["locked_cost"] === undefined || (showPilotBudgetDetails[index]["locked_cost"] !== null && showPilotBudgetDetails[index]["locked_cost"] !== undefined && validateNumbersOnly(showPilotBudgetDetails[index]["locked_cost"]))) {
                    this.postPilotBudgetDetails(showPilotBudgetDetails[index], showPilotBudgetDetails[index].show_season_pilot_budget_details_id);
                }
            }
        }
        else {
            this.setState({ errorFlag: true, errorMsg: "'Details' & 'Est. Cost' column data is required." });
        }
    };
    handleReadCallback = (index) => {
        let allDetails = [...this.state.renderList]
        allDetails[index].editing = false
        this.setState({ renderList: allDetails })
    }
    handleAddNewItem = (newDataItem) => {
        this.setState({ errorFlag: false, errorMsg: null });
        let newPilotBudgetData = {
            //detail: newDataItem?.detail_id || null,
            budget_detail_code_id: newDataItem?.budget_detail_code_id || null,
            description: newDataItem?.description || null,
            secondary_description: newDataItem?.secondary_description || null,
            est_cost: newDataItem?.est_cost || null,
            locked_cost: newDataItem?.locked_cost || null,
            efc: newDataItem?.efc || null,
        };

        if (newPilotBudgetData["budget_detail_code_id"] !== null && newPilotBudgetData["budget_detail_code_id"] !== undefined && newPilotBudgetData["est_cost"] !== null && newPilotBudgetData["est_cost"] !== undefined && validateNumbersOnly(newPilotBudgetData["est_cost"])) {
            if (newPilotBudgetData["efc"] === null || newPilotBudgetData["efc"] === undefined || (newPilotBudgetData["efc"] !== null && newPilotBudgetData["efc"] !== undefined && validateNumbersOnly(newPilotBudgetData["efc"]))) {
                if (newPilotBudgetData["locked_cost"] === null || newPilotBudgetData["locked_cost"] === undefined || (newPilotBudgetData["locked_cost"] !== null && newPilotBudgetData["locked_cost"] !== undefined && validateNumbersOnly(newPilotBudgetData["locked_cost"]))) {
                    this.postPilotBudgetDetails(newPilotBudgetData, 'newItemPost');
                }
            }
        }
        else {
            this.setState({ errorFlag: true, errorMsg: "'Details' & 'Est. Cost' column data is required." });
        }
    };
    handleClickModal = (event) => {
        this.setState({
            setModal: event.currentTarget
        })
    };
    handleCloseModal = () => {
        this.setState({
            setModal: null
        })
    };
    onClose = () => {
        if (this.state.fieldChanged) {
            this.setState({ showError: true });
        } else {
            this.closPopup()
        }
    }
    deletePilotBudgetDetails = (pilotDetails) => {
        let pilotData = {};
        pilotData = {
            ...pilotDetails,
            is_delete: 1,
            show_season_id: this.props?.show_season_id,
            season_id: this.props?.initialRecordData?.season_id,
            show_id: this.props?.initialRecordData?.show_id,
            apply_to: this.state.mainPilotDetails?.customize_title ? this.state.mainPilotDetails?.customize_title : "PILOT",
            show_season_pilot_budget_header_id: this.state.pilotDetails?.show_season_pilot_budget_header_id,
            show_season_pilot_budget_details_id: pilotDetails?.show_season_pilot_budget_details_id
        };
        this.setState({ isSubmit: pilotDetails?.show_season_pilot_budget_details_id });
        ClearTrackService.postDataParams(
            Constants.ClearTrackServiceBaseUrl + `/showSeasonPilotBudgetDetails`,
            pilotData,
            this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id
        ).then(
            (response) => {
                if (response.data.error) {
                    this.setState({ isSubmit: false });
                    this.props?.notificationComponent("error")
                } else {
                    // this.setState({
                    //     isSubmit: false,
                    // });
                    this.props?.notificationComponent("success")
                    this.getPilotBudgetDetails(false);
                }
            },
            (err) => {
                this.setState({ isSubmit: false });
                this.props?.notificationComponent("error")
                console.log("Post showProgramDetails Details error: " + err);
            }
        );
    };
    handleOnChange = (field, value) => {
        if (field === "is_no_mt") {
            this.setState({
                pilotBudgetHeaderDetails: {
                    ...this.state.pilotBudgetHeaderDetails,
                    [field]: value ? 1 : 0,
                },
            });
        }
        else if (value != "") {
            this.setState({
                pilotBudgetHeaderDetails: {
                    ...this.state.pilotBudgetHeaderDetails,
                    [field]: value,
                },
            });
        }
        else {
            this.setState({
                pilotBudgetHeaderDetails: {
                    ...this.state.pilotBudgetHeaderDetails,
                    [field]: null,
                },
            });
        }
    };

    getPermissions(permissions) {
        if (permissions.length > 0) {
            canEditBudgetDetails = permissions.filter((obj) => obj.permission_name === "Pilot Budget" && obj.view === 1 && obj.edit === 1);
            canEditBudgetDetails = canEditBudgetDetails.length > 0 ? true : false;
            if (canEditBudgetDetails === false) {
                let tempConfig = pilotBudgetConfig;
                let actionsToRemove = ["pen", "times"];
                let tempActions = tempConfig.actions.filter(item => !actionsToRemove.includes(item));
                pilotBudgetConfig.actions = tempActions;
                this.setState({ config: pilotBudgetConfig })
            }
            // canGeneratePDF = permissions.filter((obj, index) => obj.permission_name === "Can generate a PDF of a budget");
            // canGeneratePDF = canGeneratePDF.length > 0 ? true : false;

        }
    }
    downloadUrl = (url) => {
        if (url) {
            fetch(url).then(response => response.blob()).then(blob => {
                const link = document.createElement('a');
                link.href = url
                //link.setAttribute('download', load?.fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                this.setState({ isPdfPosting: false, isExcelPosting: false }, () => {
                    this.closeGenerateExcelPopup();
                    this.closeGeneratePdfPopup();
                });
                this.props?.notificationComponent("success");
            })
        }
    }
    generateReport = (identifier, reportName, searchPayload) => {
        if (!searchPayload || !reportName) {
            return;
        }
        Object.keys(searchPayload).map(obj => {
            if (searchPayload[obj] === undefined) {
                searchPayload[obj] = null;
            }
        })
        let encryptTedStr = btoa(JSON.stringify(searchPayload));
        load = {
            'reportName': reportName,
            'fileName': "Proposed Music Budget",
            'searchJson': encryptTedStr,
            'showHtml': 0,
            'encryptionType': 1,
            'tenantId': this.props?.userContext?.active_tenant?.tenant_id,
            'divisionId': this.props.division_id,
        }
        if (identifier === "excel") {
            load.payload = "exportExcel";
        }
        if (identifier === "pdf" || identifier === "email") {
            load.payload = "exportPdf";
        }
        ClearTrackService.getDataWS(load).then(webSocketConn => {
            webSocketConn.onmessage = (e) => {
                console.log("response generateReport", e);
                let response = JSON.parse(e.data);
                console.log("response?.statusCode", response?.statusCode);
                if (response?.statusCode == 200) {
                    if (identifier === "pdf") {
                        this.setState({ isPdfPosting: false });
                    } else if(identifier === "email"){
                        this.setState({isEmailLoader: false})
                    }
                    else {
                        this.setState({ isExcelPosting: false });
                    }
                    if(identifier != "email") {
                        this.downloadUrl(response?.body, identifier);
                    } else {
                        this.props?.notificationComponent('success',"Emails have been saved to your Drafts")
                    }
                }else {
                    this.setState({ isPdfPosting: false, isEmailLoader: false, isExcelPosting: false })
                }
            }
        })
        // let queryParams = `reportName=${reportName}&searchJson=${encryptTedStr}`
        // return ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `${url}${queryParams}`, this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id, true)
    }
    generatePdf = (identifier) => {
        let payloadToPass, reportApi;
        if (identifier === "excel") {
            this.setState({ postInitiated: true, isExcelPosting: true });
            payloadToPass = this.state.excelPayload;
            reportApi = "rptProposedMusicBudgetReportExcel";
            payloadToPass["isOpenExisting"] = 0;
        }
        if (identifier === "pdf") {
            this.setState({ postInitiated: true, isPdfPosting: true });
            payloadToPass = this.state.pdfPayload;
            reportApi = "rptProposedMusicBudgetReport";
        }if(identifier === "email"){
            this.setState({ postInitiated: true, isEmailLoader: true });
            payloadToPass = this.state.pdfPayload;
            payloadToPass["send_email"] = 1,
            payloadToPass["is_draft"] = 1, 
            payloadToPass["has_attachment"] = 1,
            payloadToPass["report_type"] = "PROPOSED_MUSIC_BUDGET",
            reportApi = "rptProposedMusicBudgetReport";
            
        }
        this.generateReport(identifier, reportApi, payloadToPass);
    }

    //     then(response => {
    //         if (response.data.error) {
    //             if (identifier === "excel") {
    //                 this.setState({ isExcelPosting: false });
    //             } else {
    //                 this.setState({ isPdfPosting: false });
    //             }
    //             this.props?.notificationComponent(true, "fail");
    //             // this.props.fieldChanged(true, "reportNotFound");
    //         }
    //         else {
    //             let url = response.data;
    //             if (identifier === "pdf") {
    //                 this.downloadUrl(url, "PilotBudgetReport");
    //             }
    //             if (identifier === "excel") {
    //                 this.downloadUrl(url, "PilotBudgetExcelReport");
    //             }
    //         }
    //     }, (err) => {
    //         if (identifier === "excel") {
    //             this.setState({ isExcelPosting: false });
    //         } else {
    //             this.setState({ isPdfPosting: false });
    //         }
    //         this.props?.notificationComponent(true, "fail");
    //         console.log(err);
    //         // this.props.fieldChanged(true, "reportFail");
    //     })
    // }

    fetchData = (field, url) => {
        this.setState({ isLoading: true });
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                switch (field) {
                    case 'getInitialValues':
                        if (response.data.length > 0) {
                            if (response.data[0]?.pilot_maintance?.length > 0) {
                                this.setState({
                                    mainPilotDetails: response.data[0]?.pilot_maintance[0],
                                    excelPayload: {
                                        //"show_season_pilot_id": this.props.mainPilotDetails.show_season_pilot_id,
                                        "show_season_pilot_id": response.data[0]?.pilot_maintance[0].show_season_pilot_id,
                                        "include_pilot": 1,
                                        "include_pattern": 1,
                                        "include_amort": 1,
                                        "include_locked_cost": 1,
                                        "include_efc": 1,
                                        "include_clearance_budget": response.data[0]?.pilot_maintance[0]?.apply_to !== "AMORT" ? 1 : 0,
                                        "is_box_upload": 1,
                                        "include_clearance_cost": "est",
                                        "final_clearance_budget": []
                                    },
                                    pdfPayload: {
                                        //"show_season_pilot_id": this.props.mainPilotDetails.show_season_pilot_id,
                                        "show_season_pilot_id": response.data[0]?.pilot_maintance[0].show_season_pilot_id,
                                        "include_pilot": 1,
                                        "include_pattern": 1,
                                        "include_amort": 1,
                                        "include_locked_cost": 1,
                                        "include_efc": 1,
                                        "include_clearance_budget": response.data[0]?.pilot_maintance[0]?.apply_to !== "AMORT" ? 1 : 0,
                                        "is_box_upload": 1,
                                        "include_clearance_cost": "est",
                                        "final_clearance_budget": []
                                    }
                                }, () => {
                                    this.getPilotBudgetDetails()
                                });
                            }
                        }
                        break;
                    case 'pilotCBDetailsHeader':
                        let clearanceBudgetTemp = null;
                        clearanceBudgetTemp = response?.data?.length > 0 && response?.data?.find((item) =>
                            item.is_final_budget === 1
                        );
                        this.setState({ clearanceTabList: clearanceBudgetTemp, isLoading: false });
                        break;
                }
            },
                (err) => {
                    this.setState({ isLoading: false })
                    console.log("Error in fetching Address Types:", err)
                })
    }
    onCheckboxChange = (field, value, identifier, budgetValuePassed = []) => {
        if (identifier === "excel") {
            if (field === "final_clearance_budget") {
                this.setState({
                    excelPayload: {
                        ...this.state.excelPayload,
                        [field]: value ? budgetValuePassed : []
                    },
                });
            } else {
                this.setState({
                    excelPayload: {
                        ...this.state.excelPayload,
                        [field]: value ? 1 : 0
                    },
                });
            }
        }
        if (identifier === "pdf") {
            if (field === "final_clearance_budget") {
                this.setState({
                    pdfPayload: {
                        ...this.state.pdfPayload,
                        [field]: value ? budgetValuePassed : []
                    },
                });
            } else {
                this.setState({
                    pdfPayload: {
                        ...this.state.pdfPayload,
                        [field]: value ? 1 : 0
                    },
                });
            }
        }

    }
    handleRadioChange(e, identifier) {
        if (identifier === "excel") {
            this.setState((prevState) => ({
                excelPayload: {
                    ...prevState.excelPayload,
                    ["include_clearance_cost"]: e.target.value
                }
            }))
        } else {
            this.setState((prevState) => ({
                pdfPayload: {
                    ...prevState.pdfPayload,
                    ["include_clearance_cost"]: e.target.value
                }
            }))
        }
    }
    openGenerateExcelPopup = (e) => {
        this.setState({
            setGenerateExcelPopup: e.currentTarget
        })
    }
    closeGenerateExcelPopup = () => {
        this.setState((prevState) => ({
            excelPayload: {
                ...prevState.excelPayload,
                "include_pilot": 1,
                "include_pattern": 1,
                "include_amort": 1,
                "include_locked_cost": 1,
                "include_efc": 1,
                "include_clearance_cost": "est",
                "final_clearance_budget": []
            },
            setGenerateExcelPopup: null
        }));
    };
    openGeneratePdfPopup = (e) => {
        this.setState({
            setGeneratePdfPopup: e.currentTarget
        })
    }
    closeGeneratePdfPopup = () => {
        this.setState((prevState) => ({
            pdfPayload: {
                ...prevState.pdfPayload,
                "include_pilot": 1,
                "include_pattern": 1,
                "include_amort": 1,
                "include_locked_cost": 1,
                "include_efc": 1,
                "include_clearance_cost": "est",
                "final_clearance_budget": []
            },
            setGeneratePdfPopup: null
        }));
        // this.setState({
        //     setGeneratePdfPopup: null,
        // pdfPayload: {
        //     "show_season_id": this.props?.show_season_id,
        //     "show_season_pilot_clearance_budget_id": this.props?.tabValue,
        //     "is_budget_only": 1,
        //     "is_hide_series_pattern": 0,
        //     "is_hide_marketting_cost": 0,
        //     "is_hide_diclaimer": 0
        // }
        // })
    };
   
    render() {
        let openModal = Boolean(this.state.setModal);
        let idModal = open ? 'modal-popover' : undefined;
        let openPdfModal = Boolean(this.state.setPdfModal);
        let idPdfModal = open ? 'modal-popover' : undefined;
        let openBusinessAffairsActions = Boolean(this.state.setGeneratePdfPopup);
        let openBusinessAffairsActionsEXcel = Boolean(this.state.setGenerateExcelPopup);
        return (
            <div className="Pilot-Container">
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardText>
                            {this.state.isLoading ? (
                                <div style={{ textAlign: "center" }}>
                                    <CircularProgress size={24} />
                                </div>
                            ) : (
                                    <>
                                        <MDBRow>
                                            <MDBCol md={5}>
                                                <MDBRow>
                                                    <MDBCol md={3}>
                                                        <BasicLabel text={"Script Date"} />
                                                        <p>{getFormattedDate(this.state.pilotDetails?.script_date) || "None"}</p>
                                                    </MDBCol>
                                                    <MDBCol md={3}>
                                                        <BasicLabel text={"Preparation Date"} />
                                                        <p>{getFormattedDate(this.state.pilotDetails?.preparation_date) || "None"}</p>
                                                    </MDBCol>
                                                    <MDBCol md={3}>
                                                        <BasicLabel text={"Comments"} />
                                                        <p>{this.state.pilotDetails?.comments || "None"}</p>
                                                    </MDBCol>
                                                    <MDBCol md={1} className={"btn-w"}>
                                                        {canEditBudgetDetails && <>
                                                            <BasicButton variant="contained"
                                                                type="inline"
                                                                text={"Edit"}
                                                                onClick={this.handleClickModal}
                                                            />
                                                            <Popover
                                                                id={idModal}
                                                                open={openModal}
                                                                className={"pop-over"}
                                                                anchorEl={this.state.setModal}
                                                                onClose={this.handleCloseModal}
                                                                anchorReference="anchorOrigin"
                                                                anchorOrigin={{
                                                                    horizontal: "right",
                                                                    vertical: "bottom "
                                                                }}
                                                                transformOrigin={{
                                                                    horizontal: "left",
                                                                    vertical: "top"
                                                                }}
                                                                PaperProps={{ style: { width: '16%', backgroundColor: '#e6e6e6' } }}
                                                                anchorPosition={{ top: 700, left: 300 }}
                                                            >

                                                                <PilotModal pilotBudgetHeaderDetails={this.state.pilotBudgetHeaderDetails}
                                                                    notificationComponent={this.props?.notificationComponent}
                                                                    toggleRefresh={this.getPilotBudgetDetails.bind(this)}
                                                                    handleOnChange={this.handleOnChange}
                                                                    handleCloseModal={this.handleCloseModal}
                                                                    initialRecordData={this.props?.initialRecordData}
                                                                />
                                                            </Popover>
                                                        </>}
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCol>
                                            <MDBCol md={7}>
                                                <MDBRow>
                                                    <MDBCol md={5}></MDBCol>
                                                    <MDBCol md={7} className="sendApprovalRequestWrapper">
                                                        {this.state.excelPayload?.show_season_pilot_id &&
                                                            <>
                                                                <BasicButton
                                                                    icon={"file-excel"}
                                                                    text=" "
                                                                    variant={"outlined"}
                                                                    onClick={this.openGenerateExcelPopup}
                                                                />
                                                                <Popover
                                                                    open={openBusinessAffairsActionsEXcel}
                                                                    anchorEl={this.state.setGenerateExcelPopup}
                                                                    onClose={this.closeGenerateExcelPopup}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    PaperProps={{ style: { width: '30%' } }} >
                                                                    <MDBRow>
                                                                        <BasicLabel text={"Budget Criterial"} />
                                                                        <MDBRow>
                                                                            <MDBCol md={4}>
                                                                                <BasicCheckbox
                                                                                    id={"include_pilot_excel"}
                                                                                    label={"Include Pilot"}
                                                                                    checked={this.state.excelPayload?.include_pilot}
                                                                                    onChange={(e) => this.onCheckboxChange("include_pilot", e.target.checked, "excel")}
                                                                                />
                                                                            </MDBCol>
                                                                            <MDBCol md={4}>
                                                                                <BasicCheckbox
                                                                                    id={"include_pattern_excel"}
                                                                                    label={"Include Pattern"}
                                                                                    checked={this.state.excelPayload?.include_pattern}
                                                                                    onChange={(e) => this.onCheckboxChange("include_pattern", e.target.checked, "excel")}
                                                                                />
                                                                            </MDBCol>
                                                                            <MDBCol md={4}>
                                                                                <BasicCheckbox
                                                                                    id={"include_amort_excel"}
                                                                                    label={"Include Amort"}
                                                                                    checked={this.state.excelPayload?.include_amort}
                                                                                    onChange={(e) => this.onCheckboxChange("include_amort", e.target.checked, "excel")}
                                                                                />
                                                                            </MDBCol>
                                                                        </MDBRow>
                                                                        <MDBRow>
                                                                            <MDBCol md={6}>
                                                                                <BasicCheckbox
                                                                                    id={"include_locked_cost"}
                                                                                    label={"Include Lckd Cost"}
                                                                                    checked={this.state.excelPayload?.include_locked_cost}
                                                                                    onChange={(e) => this.onCheckboxChange("include_locked_cost", e.target.checked, "excel")}
                                                                                />
                                                                            </MDBCol>
                                                                            <MDBCol md={6}>
                                                                                <BasicCheckbox
                                                                                    id={"include_efc"}
                                                                                    label={"Include EFC"}
                                                                                    checked={this.state.excelPayload?.include_efc}
                                                                                    onChange={(e) => this.onCheckboxChange("include_efc", e.target.checked, "excel")}
                                                                                />
                                                                            </MDBCol>
                                                                        </MDBRow>
                                                                    </MDBRow>
                                                                    <MDBRow>
                                                                        <BasicLabel text={"Clearance Cost"} />
                                                                        <MDBCol md={4}>
                                                                            <Radio name="radio-buttons" checked={this.state.excelPayload?.include_clearance_cost == "est"}
                                                                                onChange={(e) => this.handleRadioChange(e, "excel")} value="est" />
                                                                            <span className="popover-content-value">Est Cost</span>
                                                                        </MDBCol>
                                                                        <MDBCol md={4}>
                                                                            <Radio name="radio-buttons" checked={this.state.excelPayload?.include_clearance_cost == 'lckd'}
                                                                                onChange={(e) => this.handleRadioChange(e, "excel")} value="lckd" />
                                                                            <span className="popover-content-value">Lckd Cost</span>
                                                                        </MDBCol>
                                                                        <MDBCol md={4}>
                                                                            <Radio name="radio-buttons" checked={this.state.excelPayload?.include_clearance_cost == 'efc'}
                                                                                onChange={(e) => this.handleRadioChange(e, "excel")} value="efc" />
                                                                            <span className="popover-content-value">EFC</span>
                                                                        </MDBCol>
                                                                    </MDBRow>
                                                                    <MDBRow className="pop-border">
                                                                        {this.state.clearanceTabList?.show_season_pilot_clearance_budget_id &&

                                                                            <BasicCheckbox
                                                                                id={"excel" + this.state.clearanceTabList?.show_season_pilot_clearance_budget_id}
                                                                                label={this.state.clearanceTabList?.budget_name}
                                                                                checked={this.state.excelPayload?.final_clearance_budget === this.state.clearanceTabList?.show_season_pilot_clearance_budget_id}
                                                                                onChange={(e) => this.onCheckboxChange("final_clearance_budget", e.target.checked, "excel", this.state.clearanceTabList?.show_season_pilot_clearance_budget_id)}
                                                                            />
                                                                        }
                                                                    </MDBRow>
                                                                    <MDBRow>
                                                                        <MDBCol md={8}></MDBCol>
                                                                        <MDBCol md={4} className="saveCancelWrapper">
                                                                            <BasicButton
                                                                                icon={this.state.isExcelPosting ? " " : "save"}
                                                                                text={this.state.isExcelPosting ? <CircularProgress color="inherit" size={20} /> : " "}
                                                                                variant={"contained"}
                                                                                onClick={() => this.generatePdf("excel")}
                                                                            />
                                                                            <BasicButton
                                                                                icon={"times"}
                                                                                text={" "}
                                                                                variant={"outlined"}
                                                                                onClick={this.closeGenerateExcelPopup}
                                                                            />
                                                                        </MDBCol>
                                                                    </MDBRow>
                                                                </Popover>
                                                            </>
                                                        }
                                                        {this.state.pdfPayload?.show_season_pilot_id &&
                                                            <>
                                                                <BasicButton
                                                                    icon={"file-pdf"}
                                                                    text=" "
                                                                    variant={"outlined"}
                                                                    onClick={this.openGeneratePdfPopup}
                                                                />
                                                                <Popover
                                                                    open={openBusinessAffairsActions}
                                                                    anchorEl={this.state.setGeneratePdfPopup}
                                                                    onClose={this.closeGeneratePdfPopup}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    PaperProps={{ style: { width: '30%' } }} >
                                                                    <MDBRow>
                                                                        <BasicLabel text={"Budget Criterial"} />
                                                                        <MDBRow>
                                                                            <MDBCol md={4}>
                                                                                <BasicCheckbox
                                                                                    id={"include_pilot_pdf"}
                                                                                    label={"Include Pilot"}
                                                                                    checked={this.state.pdfPayload?.include_pilot}
                                                                                    onChange={(e) => this.onCheckboxChange("include_pilot", e.target.checked, "pdf")}
                                                                                />
                                                                            </MDBCol>
                                                                            <MDBCol md={4}>
                                                                                <BasicCheckbox
                                                                                    id={"include_pattern_pdf"}
                                                                                    label={"Include Pattern"}
                                                                                    checked={this.state.pdfPayload?.include_pattern}
                                                                                    onChange={(e) => this.onCheckboxChange("include_pattern", e.target.checked, "pdf")}
                                                                                />
                                                                            </MDBCol>
                                                                            <MDBCol md={4}>
                                                                                <BasicCheckbox
                                                                                    id={"include_amort_pdf"}
                                                                                    label={"Include Amort"}
                                                                                    checked={this.state.pdfPayload?.include_amort}
                                                                                    onChange={(e) => this.onCheckboxChange("include_amort", e.target.checked, "pdf")}
                                                                                />
                                                                            </MDBCol>
                                                                        </MDBRow>
                                                                        <MDBRow>
                                                                            <MDBCol md={6}>
                                                                                <BasicCheckbox
                                                                                    id={"include_locked_cost_pdf"}
                                                                                    label={"Include Lckd Cost"}
                                                                                    checked={this.state.pdfPayload?.include_locked_cost}
                                                                                    onChange={(e) => this.onCheckboxChange("include_locked_cost", e.target.checked, "pdf")}
                                                                                />
                                                                            </MDBCol>
                                                                            <MDBCol md={6}>
                                                                                <BasicCheckbox
                                                                                    id={"include_efc_pdf"}
                                                                                    label={"Include EFC"}
                                                                                    checked={this.state.pdfPayload?.include_efc}
                                                                                    onChange={(e) => this.onCheckboxChange("include_efc", e.target.checked, "pdf")}
                                                                                />
                                                                            </MDBCol>
                                                                        </MDBRow>
                                                                    </MDBRow>
                                                                    <MDBRow>
                                                                        <BasicLabel text={"Clearance Cost"} />
                                                                        <MDBCol md={4}>
                                                                            <Radio name="radio-buttons-pdf" checked={this.state.pdfPayload?.include_clearance_cost == 'est'}
                                                                                onChange={(e) => this.handleRadioChange(e, "pdf")} value="est" />
                                                                            <span className="popover-content-value">Est Cost</span>
                                                                        </MDBCol>
                                                                        <MDBCol md={4}>
                                                                            <Radio name="radio-buttons-pdf" checked={this.state.pdfPayload?.include_clearance_cost == 'lckd'}
                                                                                onChange={(e) => this.handleRadioChange(e, "pdf")} value="lckd" />
                                                                            <span className="popover-content-value">Lckd Cost</span>
                                                                        </MDBCol>
                                                                        <MDBCol md={4}>
                                                                            <Radio name="radio-buttons-pdf" checked={this.state.pdfPayload?.include_clearance_cost == 'efc'}
                                                                                onChange={(e) => this.handleRadioChange(e, "pdf")} value="efc" />
                                                                            <span className="popover-content-value">EFC</span>
                                                                        </MDBCol>
                                                                    </MDBRow>
                                                                    <MDBRow className="pop-border">
                                                                        {this.state.clearanceTabList?.show_season_pilot_clearance_budget_id &&
                                                                            <BasicCheckbox
                                                                                id={"pdf" + this.state.clearanceTabList?.show_season_pilot_clearance_budget_id}
                                                                                label={this.state.clearanceTabList?.budget_name}
                                                                                checked={this.state.pdfPayload?.final_clearance_budget === this.state.clearanceTabList?.show_season_pilot_clearance_budget_id}
                                                                                onChange={(e) => this.onCheckboxChange("final_clearance_budget", e.target.checked, "pdf", this.state.clearanceTabList?.show_season_pilot_clearance_budget_id)}
                                                                            />
                                                                        }
                                                                    </MDBRow>
                                                                    <MDBRow>
                                                                        <MDBCol md={8}></MDBCol>
                                                                        <MDBCol md={4} className="saveCancelWrapper">
                                                                            <BasicButton
                                                                                icon={this.state.isPdfPosting ? " " : "save"}
                                                                                text={this.state.isPdfPosting ? <CircularProgress color="inherit" size={20} /> : " "}
                                                                                variant={"contained"}
                                                                                onClick={() => this.generatePdf("pdf")}
                                                                            />
                                                                            <BasicButton
                                                                                icon={"times"}
                                                                                text={" "}
                                                                                variant={"outlined"}
                                                                                onClick={this.closeGeneratePdfPopup}
                                                                            />
                                                                        </MDBCol>
                                                                    </MDBRow>
                                                                </Popover>
                                                                {/* <BasicButton
                                                                    icon={this.state.isPdfPosting ? " " : "file-pdf"}
                                                                    text={this.state.isPdfPosting ? <CircularProgress color="inherit" size={20} /> : " "}
                                                                    variant={"contained"}
                                                                    onClick={() => this.generatePdf("pdf")}
                                                                /> */}
                                                            </>

                                                        }
                                                        <BasicButton
                                                            variant={"outlined"}
                                                            type="inline"
                                                            text={this.state.isEmailLoader ? <CircularProgress color="inherit" size={20} /> : "Send Approval Request"}
                                                            onClick={() => this.generatePdf("email")}
                                                        />

                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>

                                        <MDBRow className="cueSheetTable" id="table-bg-creative">
                                            {this.state.isPostInitiated ? <p className="f-color">Details should be reqiure</p> : this.state.isLoading = false}
                                            {this.state.config.headings[0].selectOptions.length > 0 &&
                                                <>
                                                    {this.state.errorFlag && <p className="errorMessage">{this.state.errorMsg}</p>}
                                                    <TableComponent
                                                        list={this.state.renderList || []}
                                                        config={this.state.config}
                                                        editCallback={canEditBudgetDetails === true ? this.handleInlineEdits.bind(this) : false}
                                                        saveCallback={canEditBudgetDetails === true ? this.saveInlineEdits.bind(this) : false}
                                                        handleAddNewItem={canEditBudgetDetails === true ? this.handleAddNewItem.bind(this) : false}
                                                        addItemToList={canEditBudgetDetails === true ? true : false}
                                                        handleDelCallBack={canEditBudgetDetails === true ? ((id, item) =>
                                                            this.deletePilotBudgetDetails(item)) : false
                                                        }
                                                        readCallback={canEditBudgetDetails ? this.handleReadCallback.bind(this) : false}
                                                        postInitiated={this.state.isSubmit}
                                                    />
                                                </>
                                            }
                                        </MDBRow>
                                    </>
                                )}
                            <MDBRow>
                                <MDBCol md={7}></MDBCol>
                                <MDBCol md={5} className="float-r">
                                    <BasicLabel text={"Cost Before Music Licensing"} />
                                    <div className="display-Cost">
                                        <span className="greenColoredCost">${this.state.totalEstCost}</span>
                                        <span className="lgText">${this.state.totalLockedCost}</span>
                                        <span className="lgText">${this.state.totalEfc}</span>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            {this.state.mainPilotDetails?.apply_to !== 'AMORT' && <MDBRow>
                                <h6>Music Licensing Budgets</h6>
                                <div className="cueSheetTable" id="table-bg-creative">
                                    <TableComponent
                                        list={this.state.summaryBudget || []}
                                        config={this.state.config2}
                                    />
                                </div>
                            </MDBRow>}
                            {this.state.mainPilotDetails?.apply_to !== 'AMORT' ? this.state.summaryBudget?.length > 0 ?
                                this.state.summaryBudget?.map(item => <MDBRow>
                                    <MDBCol md={7}></MDBCol>
                                    <MDBCol md={5} className="float-r">
                                        <BasicLabel text={this.state.mainPilotDetails?.customize_title ? `${this.state.mainPilotDetails?.customize_title} Cost(${item?.cost_center_name})` : `Pilot Cost(${item?.cost_center_name})`} />
                                        <div className="display-Cost">
                                            <span className="greenColoredCost">${parseFloat(this.state.totalEstCost) + parseFloat(item?.est_cost?.replaceAll('$', '')?.replaceAll(',', ''))}</span>
                                            <span className="lgText">${parseFloat(this.state.totalLockedCost) + parseFloat(item?.locked_cost)}</span>
                                            <span className="lgText">${parseFloat(this.state.totalEfc) + parseFloat(item?.efc)}</span>
                                        </div>
                                    </MDBCol>
                                </MDBRow>) :
                                <MDBRow>
                                    <MDBCol md={8}></MDBCol>
                                    <MDBCol md={4} className="float-r">
                                        <BasicLabel text={this.state.mainPilotDetails?.customize_title ? `${this.state.mainPilotDetails?.customize_title} Cost` : `Pilot Cost`} />
                                        <div className="display-Cost">
                                            {/* <span>Pilot Cost</span> */}
                                            <span>${this.state.totalEstCost}</span>
                                            <span>${this.state.totalLockedCost}</span>
                                            <span>${this.state.totalEfc}</span>
                                        </div>
                                    </MDBCol>
                                </MDBRow> : null
                            }
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </div>
        )
    }
}

export default withUserContext(Pilot);
