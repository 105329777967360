import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import "./ExcelAndDoc.scss";
import { withUserContext } from "../../../contexts/UserContext";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import {EpisodeAndClearence} from "../../EpisodeAndClearence/EpisodeAndClearenceDetails/EpisodeAndClearence";
class ExcelAndPdfModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenExisting: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isOpenExisting !== prevState.isOpenExisting) {
      this.props.isOpenExisting(this.state.isOpenExisting)
    }
}
  

  handleChange = (value) => {
    if (value === "recreate") {
      this.setState({ isOpenExisting: false });
    } else {
      this.setState({ isOpenExisting: true });
    }
   
  };
  onClickingOk=()=>{
    this.props?.downloadExcel();
  }
 
  render() {
    return (
      <MDBContainer>
        <MDBRow>
          <FormControl>
            {/* <FormLabel id="demo-row-radio-buttons-group-label"> */}
              <span>Choosing open existing file will download if previously report is generated otherwise it will create new report and download</span>
            {/* </FormLabel> */}
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue={"recreate"}
              onChange={(e) => this.handleChange(e.target.value)}
            >
              <FormControlLabel
                value="recreate"
                control={<Radio />}
                label="Re Create"
              />
              <FormControlLabel
                value="openExisting"
                control={<Radio />}
                label="Open Existing"
              />
            </RadioGroup>
          </FormControl>
        </MDBRow>
        <MDBRow>
          <MDBCol md={8}></MDBCol>
          <MDBCol md={1}>
            <BasicButton
              className="btnwidth"
              variant="contained"
              type="inline"
              // text={"Ok"}
              text={
                this.props?.isExcelPosting ? (
                  <CircularProgress color="inherit" size={18} />
                ) : (
                  "Ok"
                )
              }
              onClick={this.onClickingOk}
            />
          </MDBCol>
          <MDBCol md={1}></MDBCol> 
          <MDBCol md={1}>
            <BasicButton
              type="inline"
              variant="outlined"
              text={"Close"}
              onClick={this.props?.handleClose}
              disabled={false}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}
export default withUserContext(ExcelAndPdfModal);
