import React, { useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { withUserContext } from '../../../../contexts/UserContext';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import SearchSelectField from '../../../SharedComponents/SearchSelectField';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import TableComponent from '../../../SharedComponents/Table';
import CheckRequestPopUp from './CheckRequestPopUp';
import clearTrackService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import MainViewModalComponent from "../../../../Common/MainViewModalLayout/Modal/Modal";
import MessageModal from '../../../SharedComponents/MessageModal';
import { initialOptionalRightsConfig, initialOptionalCheckRequestConfig } from './Config';
import NotesModal from './NotesModal';
import Popover from '@material-ui/core/Popover';
import { getFormattedDate, validateNumDotOnly, validateNumDotWithNegative } from '../../../../Common/Helper';
// import './SyncLicensor.scss';

let viewOptionaRightsAndCheckRequest, viewCueSheetInfo, viewLicensorNotes, editLicensorNotes, ediCueSheetInfo, viewAcquireOptionRights;
export default withUserContext(class CueSheetPublisher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetchingCueSheetDetails: false,
            expanded: false,
            setExpanded: false,
            setEditCuePopover: null,
            renderList: this.props?.lic?.[this.props?.optionalRightKey],
            config: this.props?.optionalRightsConfig,
            CueSheetPublisherConfig: this.props?.CueSheetPublisherConfig,
            // optionalRightCheckRequestList: this.props?.lic?.[this.props?.optionalRightKey]?.[0]?.[this.props?.optionalRightCheckRequestKey],
            optionalRightCheckRequestList: [],
            selectedOptionalRight: this.props?.lic?.[this.props?.optionalRightKey]?.[0],
            showPayeeModal: false,
            EditCueSheetPublisherInfo: false,
            mode: this.props?.mode,
            setModal: null,
            OpenInlineModal: false,
            OpenMainTitleInlineModal: false,
            showOptionalRightCheckReqGrid: false,
            setBCHModal: false,
            selectedOptionsFee: null,
            showValidationAlert: false,
            sharePercentageError: "Share % for this check cannot be more than this Licensor's share.",
            openPayeeModal: null,
            optionalRightId: null,
            licOptionalId: null,
            episodeBasedOptions: [],
            eps_op_cnt_list: [],
            epsOpCount: null,
            showAquireValidationCheck: false,
            showAquireValidationCheckMessage : null,
            MandatoryOptionalRightError:false,
            MandatoryOptionalRightChkRequestError:false,
            showOptionalRightGrid:false,
            optionalChkReqUpdatedOnParent:false,
            defaultPayee: null,
            isHVOptionalRight:false
        }   
    };


    componentDidMount() {
        // this.getPermissions(this.props?.permissionArrClearanceRecords)
        // this.getShowBasedEpisodes();
        if (!this.props?.isMainTitle) { //for episode and clearance, config changes are made
            let CueSheetPublisherConfig = {...this.props?.CueSheetPublisherConfig}
            if(CueSheetPublisherConfig?.buttonFields?.findIndex(item=>item.column === "eps_op_cnt") != -1){
                CueSheetPublisherConfig.headings.splice(2, 1)
                CueSheetPublisherConfig.buttonFields.splice(2, 1)
                CueSheetPublisherConfig.dataNodes.splice(2, 1)
                CueSheetPublisherConfig.inlineEdits.splice(2, 1)
                this.setState({ CueSheetPublisherConfig })
            }          
        }
        this.toggleOptionalRightGrid();
    }

    static getDerivedStateFromProps(props, state) {
        if (props?.lic?.[props?.optionalRightKey] !== state?.renderList) {
            return {
                renderList: props?.lic?.[props?.optionalRightKey],
                // optionalRightCheckRequestList: props?.lic?.[props?.optionalRightKey]?.[0]?.[props?.optionalRightCheckRequestKey]
            }
        }
        if(props?.optionalChkReqUpdated !== state?.optionalChkReqUpdatedOnParent){
            let op = props?.lic?.[props?.optionalRightKey]?.filter(item=>item?.[props?.optionalRightKey + '_id'] == state.selectedOptionalRight[props?.optionalRightKey + '_id'])
            if (op?.length > 0){
                return {
                    optionalChkReqUpdatedOnParent:props?.optionalChkReqUpdated,
                    optionalRightCheckRequestList:op?.[0]?.[props?.optionalRightCheckRequestKey] || []
                }
            }
        }
       
    }
    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps?.optionalRightsConfig) !== JSON.stringify(this.props.optionalRightsConfig)) {
            this.setState({ config: this.props.optionalRightsConfig })
        }
        if (JSON.stringify(prevProps?.CueSheetPublisherConfig) !== JSON.stringify(this.props.CueSheetPublisherConfig)) {
            this.setState({ CueSheetPublisherConfig: this.props.CueSheetPublisherConfig })
        }
        if (this.props?.lic?.editing != prevProps?.lic?.editing) {
                if(this.props.lic?.default_payee_contact_name && this.props.lic?.default_payee_remit_to_id){
                this.setState({
                     defaultPayee: { 
                        payee_remit_to: { 
                            text: this.props?.lic?.default_payee_contact_name,
                             value: this.props?.lic?.default_payee_remit_to_id 
                            } 
                        }
                })
               
            }
            if (this.props?.lic?.editing === false) {
                 this.setState({showOptionalRightCheckReqGrid:false})
                 this.toggleOptionalRightGrid();
            }
            if(this.props?.lic?.editing === true){
                // let optionalRightCheckRequestList = this.state.optionalRightCheckRequestList?.map(item=>{item.editing = true; return item;})
                // this.setState({optionalRightCheckRequestList})
                this.setState({showOptionalRightCheckReqGrid:false})
            }
        }
    }
    // getPermissions(permissions) {
    //     if (permissions.length > 0) {
    //         viewOptionaRightsAndCheckRequest = permissions.filter((obj, index) => obj.permission_name === "Can view Option Rights and Check Requests");
    //         viewOptionaRightsAndCheckRequest = viewOptionaRightsAndCheckRequest.length > 0 ? true : false;
    //         viewCueSheetInfo = permissions.filter((obj, index) => obj.permission_name === "Can view 'Cue Sheet Info' field");
    //         viewCueSheetInfo = viewCueSheetInfo.length > 0 ? true : false;
    //         ediCueSheetInfo = permissions.filter((obj, index) => obj.permission_name === "Can edit 'Cue Sheet Info' field");
    //         ediCueSheetInfo = ediCueSheetInfo.length > 0 ? true : false;
    //         viewLicensorNotes = permissions.filter((obj, index) => obj.permission_name === "Can view 'Licensor Notes' field");
    //         viewLicensorNotes = viewLicensorNotes.length > 0 ? true : false;
    //         editLicensorNotes = permissions.filter((obj, index) => obj.permission_name === "Can edit 'Licensor Notes' field");
    //         editLicensorNotes = editLicensorNotes.length > 0 ? true : false;
    //         viewAcquireOptionRights = permissions.filter((obj, index) => obj.permission_name === "Can acquire Option Rights");
    //         viewAcquireOptionRights = viewAcquireOptionRights.length > 0 ? true : false;
    //     }
    // }
    toggleOptionalRightGrid=(buttonClicked)=>{
        if(buttonClicked || this.state?.renderList?.length > 0)
        this.setState(prevState=>({
            ...prevState,
            showOptionalRightGrid : true
        })) 
        else {
            this.setState(prevState=>({
                ...prevState,
                showOptionalRightGrid : false
            })) 
        }
    }
    handleChange = (panel) => (event, isExpanded) => {
        this.setState({ expanded: isExpanded });
    };

    // getShowBasedEpisodes = () => {
    //     console.log("showseasonidcuesheetpublisher", this.props?.show_season_id)
    //     clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/showSeasonEpisodes?show_season_id=${this.props?.show_season_id}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
    //         .then(response => {
    //             let formattedList = response.data?.map(item => ({ value: item.show_season_episode_id, text: item.episode_title }));
    //             this.setState({ episodeBasedOptions: formattedList }, () => { console.log("showSeasonOptions", this.state.episodeBasedOptions) });
    //         },
    //             (err) => {
    //                 console.log("Error in fetching Details:", err)
    //             })
    // }

    handleOptionalRightPopupClick = (id, dataItem, index,e) => {
        //let selectedOptionalRight = this.props?.lic?.[this.props?.optionalRightKey][index]
        this.setState({
            setOpCheckRequestPopUp: e.currentTarget,
            selectedOptionalRightForExpand: dataItem
        })
    };

    

    handleOpPopUpOnChange = (value, field,index) => {
        let optionalRights = [...this.props?.lic?.[this.props?.optionalRightKey]];
        let updatetedOptionalRights = optionalRights?.map(item => {
            let newItem = {...item}
            if (item[this.props?.optionalRightKey + '_id']  ? item[this.props?.optionalRightKey + '_id'] === value[this.props?.optionalRightKey + '_id'] : item.id === value.id) {
                newItem.is_disallow_acquire = value?.is_disallow_acquire ? 1 : 0;
                newItem.is_exclude_letters = value?.is_exclude_letters ? 1 : 0;
                newItem.is_exclude_license = value?.is_exclude_license ? 1 : 0;
            }
            return newItem;
        })
        this.setState({renderList:updatetedOptionalRights})
        this.props?.isLicensorOptionalRightsUpdated();
        this.props?.onChangeCallback(field, updatetedOptionalRights);
    }

    handleCRPopUpOnChange = (value, field,index) => {
        let optionalRights = [...this.props?.lic?.[this.props?.optionalRightKey]];
        let isVoided = false;
        optionalRights?.map(op => {
            if (op[this.props?.optionalRightKey + '_id'] === this.state.selectedOptionalRight[this.props?.optionalRightKey + '_id']) {
                op.is_licensor_optional_check_requests_updated = 1;
                op?.[this.props?.optionalRightCheckRequestKey]?.map(item => {
                    if (item[this.props?.optionalRightCheckRequestKey + '_id'] ? item[this.props?.optionalRightCheckRequestKey + '_id'] === value[this.props?.optionalRightCheckRequestKey + '_id'] : item.id === value.id) {
                        item.check = value?.check;
                        item.date_paid = value?.date_paid;
                        item.is_exclude_batch = value?.is_exclude_batch ? 1 : 0;
                        item.is_void = value?.is_void ? 1 : 0;
                        item.is_amort = value?.is_amort ? 1 : 0;
                        if(value?.notes)    {
                            item.notes= item?.notes ? item?.notes + value?.notes : value?.notes
                            value.notes = item.notes
                        }
                        if(item?.is_void && value?.notes){
                            isVoided = true;
                        }
                        return item;
                    }
                })
                this.setState({ selectedOptionalRight: op })
                return op;
            }
        })
        this.props?.onChangeCallback(field, optionalRights);
        this.props?.isLicensorOptionalRightsUpdated();
        if(isVoided){
            let ele = document.getElementById(`${'notes_'+value[this.props?.optionalRightCheckRequestKey + '_id']+"_"+index}`)
            // ele.click();
            this.handleOpenLicensorInlineModals(value,'cr_notes',index,ele)
        }
    }

    handleOpCheckRequestPopupClick = (id, dataItem, index,e) => {
        //let selectedCheckRequest = this.state.selectedOptionalRight?.[this.props?.optionalRightCheckRequestKey]?.find(item => item[this.props?.optionalRightCheckRequestKey + '_id'] === id);
        this.setState({
            setCheckRequestPopUp: e.currentTarget,
            selectedCheckRequest: dataItem,
        })
    };

    handleShowPayeeModal = () => {
        this.setState({ showPayeeModal: true })
    }

    handleHidePayeeMOdal = () => {
        this.setState({ showPayeeModal: false })
    }

    handleOpenAcquireModal = (dataItem, nodeItem, rowIndex, event) => {
        this.setState({ showAquireValidationCheck: false, showAquireValidationCheckMessage : null})
        if(dataItem?.clearance_licensor_optional_rights_id) {
            this.setState({ showAcquireOptionModal: true })
        } else {
            this.setState({
                showAquireValidationCheck: true, showAquireValidationCheckMessage : 'Please save the licesor info first'
            })
        }
    }

    handleOpenMainTitleAcquireModal = (dataItem, nodeItem, rowIndex, event) => {
        this.setState({ showAquireValidationCheck: false, showAquireValidationCheckMessage : null})
        if(dataItem?.main_title_licensor_optional_rights_id) {
            this.setState({ OpenMainTitleInlineModal: true })
        } else {
            this.setState({
                showAquireValidationCheck: true, showAquireValidationCheckMessage : 'Please save the licesor info first'
            })
        }
    }

    handleOpenAquireOption = (dataItem, node, rowIndex, event) => {
        if (node === 'option_acquired_date' && dataItem?.is_disallow_acquire) {
            this.setState({
                showCannotAcquireAlert: true,
                cannotAcquireAlertMessage: `You cannot acquire this option because it has been marked as 'Disallow Acquire'.`,
                showAcquireOptionModal: false,
                OpenMainTitleInlineModal: false
            })
        } else { 
            if(this.props?.isMainTitle) {
                this.handleOpenMainTitleAcquireModal(dataItem, node, rowIndex, event) 
            }  else {
                this.handleOpenAcquireModal(dataItem, node, rowIndex, event)
            }
        }
    }

    handleHideInlineModal = () => {
        this.setState({ OpenInlineModal: false })
    }

    handleHideMainTitleInlineModal = () => {
        this.setState({ OpenMainTitleInlineModal: false })
    }

    onChangeCallback = (field, value, index) => {
        if (field === 'eps_op_cnt_list') {
            let val = value.length
            this.setState({ eps_op_cnt_list: value, epsOpCount: val })
        }
    }


    handleAddNewItem = (newDataItem) => {
        let currentList = this.props.lic?.[this.props?.optionalRightKey]?.length > 0 ? JSON.parse(JSON.stringify(this.props.lic?.[this.props?.optionalRightKey])) : [];
        let optionalRights = { ...initialOptionalRightsConfig };
        if (this.props?.isMainTitle) {
            optionalRights['main_title_licensor_optional_rights_id'] = null;
            delete optionalRights.clearance_licensor_optional_rights_id;
        }
        optionalRights.id = Date.now();
        optionalRights.right_id = newDataItem?.right_id || null;
        optionalRights['right_abbr'] = this.state.config?.headings[0].selectOptions?.find(item => item.value === newDataItem?.right_id)?.label;
        optionalRights.fee = newDataItem?.fee || null;
        optionalRights.term_id = newDataItem?.term_id || null;
        optionalRights.territory_id = newDataItem?.territory_id || null;
        optionalRights.opt_exp_term_id = newDataItem?.opt_exp_term_id || null;
        optionalRights.is_option_acquired = newDataItem?.option_acquired_date ? 1 : 0;
        optionalRights.option_acquired_date = newDataItem?.option_acquired_date || null;
        optionalRights.incl_init = newDataItem?.incl_init || 0;
        optionalRights.is_cr = newDataItem?.is_cr || 0;
        optionalRights.is_new = true;
        optionalRights['editing'] = true;
        currentList.push(optionalRights);
        this.props?.isLicensorOptionalRightsUpdated();
        this.props?.onChangeCallback(this.props?.optionalRightKey, currentList)
        this.setState({ renderList: currentList }, () => {
            this.setState({ optionalRightsListUpdated: true })
        })
    }

    handleAddNewItemPayees = (newDataItem) => {
        let optinalRights = this.props.lic?.[this.props?.optionalRightKey]?.length > 0 ? JSON.parse(JSON.stringify(this.props.lic?.[this.props?.optionalRightKey])) : [];
        let optionalRightPayee = { ...initialOptionalCheckRequestConfig };
        optinalRights?.map(item => {
            if (item[this.props?.optionalRightKey + '_id'] === this.state.selectedOptionalRight[this.props?.optionalRightKey + '_id']) {
                let currentList = item?.[this.props?.optionalRightCheckRequestKey]?.length > 0 ? [...item?.[this.props?.optionalRightCheckRequestKey]]:[];
                item.is_licensor_optional_check_requests_updated = 1;
                optionalRightPayee[this.props?.optionalRightKey + '_id'] = item[this.props?.optionalRightKey + '_id'];
                if (this.props?.isMainTitle) {
                    optionalRightPayee['main_title_licensor_optional_check_requests_id'] = null;
                    delete optionalRightPayee.clearance_licensor_optional_check_requests_id;
                }
                optionalRightPayee.id = Date.now();
                optionalRightPayee.payee_remit_to = newDataItem?.payee_remit_to || null;
                optionalRightPayee['payee_remit_to_name'] = this.state.CueSheetPublisherConfig?.headings?.[1]?.selectOptions?.find(item => item.value === newDataItem?.payee_remit_to?.value)?.text;
                optionalRightPayee.lic_per = newDataItem?.lic_per || null;
                optionalRightPayee.share = newDataItem?.share || null;
                optionalRightPayee.fee = newDataItem?.fee || null;
                optionalRightPayee.lic_per = newDataItem?.lic_per || null;
                optionalRightPayee.requested_date = newDataItem?.requested_date || null;
                optionalRightPayee.inv = `${this.props?.show_name} ${this.props?.selectedSong?.song_title}`;
                optionalRightPayee.notes = newDataItem?.notes || null;
                optionalRightPayee.eps_op_cnt = 0 || null;
                optionalRightPayee['editing'] = true;
                optionalRightPayee.is_new = true;
                currentList.push(optionalRightPayee);
                item[this.props?.optionalRightCheckRequestKey] = [...currentList];
                this.setState({ optionalRightCheckRequestList: currentList })
            }
        })
        this.props?.onChangeCallback(this.props?.optionalRightKey, optinalRights)
        this.props?.isLicensorOptionalRightsUpdated();
        this.setState({ renderList: optinalRights }, () => {
            this.setState({
                optionalPayeeListUpdated: true
            })
        })
    }

    handleDelete = async (tableName, id, dataItem) => {
        switch (tableName) {
            case 'clearance_licensor_optional_rights': {
                let currentOptionalRights = [...this.props.lic?.[this.props?.optionalRightKey]];
                currentOptionalRights?.map(item => {
                    if (id ? item[this.props?.optionalRightKey + '_id'] === id : item.id === dataItem.id) {
                        item['is_delete'] = 1;
                        return item;
                    }
                })
                //set in-active optional right
                let temp = [];
                this.props?.inActiveOptionalRights?.map((item) => {
                    temp.push(item);
                });
                let optionalRightList = currentOptionalRights?.filter(item => item.is_delete === 1);
                temp.push(optionalRightList[0]);
                this.props?.handleInActiveOptionalRights(temp)

                //filter active optional right items to render in table
                // let filteredList = currentOptionalRights?.filter(item => item[this.props?.optionalRightKey + '_id'] !== id);
                let filteredList = currentOptionalRights?.filter(item => item.is_delete != 1)
                this.props?.onChangeCallback(this.props?.optionalRightKey, filteredList)
                await this.props?.isLicensorOptionalRightsUpdated();
                await this.props?.handleInActiveOptionalRightCheckReq([], null)
                this.setState({ renderList: filteredList }, () => {
                    this.setState({ selectedOptionalRight: null, optionalRightCheckRequestList: [] })
                })
                // this.props?.postLicensorData();
            }
                break;
            case 'clearance_licensor_optional_check_requests': {
                let optionalRights = [...this.props.lic?.[this.props?.optionalRightKey]];
                optionalRights?.map((item, index) => {
                    if (item[this.props?.optionalRightKey + '_id'] === dataItem[this.props?.optionalRightKey + '_id']) {
                        item?.[this.props?.optionalRightCheckRequestKey]?.map(cr => {
                            if (id ? cr[this.props?.optionalRightCheckRequestKey + '_id'] === id : cr.id === dataItem.id) {
                                // cr['payee_remit_to_id'] = cr.payee_remit_to?.value;
                                cr['is_delete'] = 1;
                                return cr;
                            }
                        })
                        //set in-active optional right check request
                        let temp = [];
                        this.props?.inActiveOptionalRightCheckRequest?.map((cr) => {
                            temp.push(cr);
                        });
                        let optionalRightCheckReqList = item?.[this.props?.optionalRightCheckRequestKey]?.filter(cr => cr.is_delete === 1);
                        temp.push(optionalRightCheckReqList[0]);
                        this.props?.handleInActiveOptionalRightCheckReq(temp, index)

                        //filter active optional right check request items to render in table
                        //let filteredPayeeList = item?.[this.props?.optionalRightCheckRequestKey]?.filter(payee => payee[this.props?.optionalRightCheckRequestKey + '_id'] !== id);
                        let filteredPayeeList = item?.[this.props?.optionalRightCheckRequestKey]?.filter(payee => payee.is_delete != 1);
                        item[this.props?.optionalRightCheckRequestKey] = filteredPayeeList;
                        item.is_licensor_optional_check_requests_updated = 1;
                        this.setState({ optionalRightCheckRequestList: filteredPayeeList })
                        return item;
                    }
                })
                this.props?.onChangeCallback(this.props?.optionalRightKey, optionalRights)
                await this.props?.isLicensorOptionalRightsUpdated();
                this.setState({ renderList: optionalRights })
                // this.props?.postLicensorData();
            }
                break;
        }
        // POST Licensor API call
    }

    handleEditCueSheetPublisherInfo = () => {
        this.setState({ EditCueSheetPublisherInfo: true })
    }

    handleHideEditCueSheetPublisherInfo = () => {
        this.setState({ EditCueSheetPublisherInfo: false })
    }

    updatePayeeGrid = (e, dataItem, index) => {
        let optionalRightCheckRequestList = this.state.optionalRightCheckRequestList;
        optionalRightCheckRequestList = dataItem[this.props?.optionalRightCheckRequestKey];
        this.setState({
            optionalRightCheckRequestList,
            selectedOptionalRight: dataItem
        })
    }

    updateOptCheckRequest = (listEdit, index, nodeName) => {
        let payeeCheck = listEdit[index]?.hasOwnProperty([this.props?.optionalRightCheckRequestKey + '_id'])
        if (payeeCheck) {
            let updatedOptionalRightCheckRequests = listEdit;
            let optionalRights = this.state.renderList;
            optionalRights?.map(op => {
                if (op?.[this.props?.optionalRightKey + '_id'] === updatedOptionalRightCheckRequests[index]?.[this.props?.optionalRightKey + '_id']) {
                    op.is_licensor_optional_check_requests_updated = 1;
                    op[this.props?.optionalRightCheckRequestKey] = updatedOptionalRightCheckRequests;
                    return op;
                }
            })
            this.props?.onChangeCallback(this.props?.optionalRightKey, optionalRights);
        } else {
            console.log("check the nodeName", nodeName)
            if (nodeName === 'right_id') {
                let updatedOptionalRights = listEdit;
                let optionalRights = this.state.renderList;
                let rightDetails = this.state.config?.headings?.[0]?.selectOptions?.find(r => r.value === updatedOptionalRights[index]?.right_id);
                optionalRights[index].right_id = updatedOptionalRights[index]?.right_id;
                optionalRights[index].term_id = rightDetails?.default_term_id || optionalRights[index].term_id;
                optionalRights[index].territory_id = rightDetails?.default_territory_id || optionalRights[index].territory_id;
                optionalRights[index].opt_exp_term_id = rightDetails?.default_exp_term_id || optionalRights[index].opt_exp_term_id;
                this.setState({ renderList: optionalRights })
                this.props?.onChangeCallback(this.props?.optionalRightKey, optionalRights);
            } 
             else {
              
                if(nodeName === "fee"){
                    let value = listEdit?.[index]?.[nodeName];
                    if(value){
                        this.setState({selectedOptionsFee : parseInt(value)},()=>{
                            let optionalChkReq = listEdit?.[index]?.[this.props?.optionalRightCheckRequestKey]?.map(item=>{
                                let newItem = {...item}
                                if(newItem?.lic_per){
                                    newItem.fee = this.calculateLicShareAmount(newItem?.lic_per,newItem?.share,null);
                                }  
                                return newItem;                        
                            })
                            // console.log("updatedOptionalChkReq by fee",optionalChkReq);
                            listEdit[index][this.props?.optionalRightCheckRequestKey] = [...optionalChkReq]
                            this.props?.onChangeCallback(this.props?.optionalRightKey, listEdit);
                            this.setState({
                                optionalPayeeListUpdated: true,
                                optionalRightCheckRequestList:[...optionalChkReq]
                            })
                        })
                    }
                   
                }
                else{

                    console.log("listEdit",listEdit)
                    this.props?.onChangeCallback(this.props?.optionalRightKey, listEdit);
                }
              
            }
            this.props?.isLicensorOptionalRightsUpdated();
        }
        if (nodeName === "incl_init") {
            let optionalRights = [...listEdit];
            if (!optionalRights[index]?.is_option_acquired) {
                if (listEdit[index].incl_init) {
                    this.props?.handleAddOptionFeeToCheckRequest(listEdit[index], true);
                } else {
                    this.props?.handleAddOptionFeeToCheckRequest(listEdit[index], false);
                }
            } else {
                optionalRights[index]['incl_init'] = 0;
                this.props?.onChangeCallback(this.props?.optionalRightKey, optionalRights);
            }
            // if (listEdit[index].incl_init) {
            //     this.props?.handleAddOptionFeeToCheckRequest(listEdit[index], true);
            // } else {
            //     this.props?.handleAddOptionFeeToCheckRequest(listEdit[index], false);
            // }
        }
    }

    calculateLicShareAmount(lic, share, initFee) {
        let mainShare = this.props?.lic?.share, totalFee = this.state.selectedOptionsFee, mainLic = 100
        // calculate LIC
        if (!lic && share) {
            return (parseFloat(mainLic) * parseFloat(share) / parseFloat(mainShare)).toFixed(3);
        }
        // calcualte SHARE
        else if (lic && !share) {
            return (parseFloat(mainShare) * parseFloat(lic) / parseFloat(mainLic))?.toFixed(3);
        }
        // calculate INIT fee
        else if (!initFee && lic) {
            return (parseFloat(totalFee) * parseFloat(lic) / parseFloat(mainLic))?.toFixed(3);
        }
        
        else if (!initFee && share) {
            return (parseFloat(totalFee) * parseFloat(share) / parseFloat(mainShare))?.toFixed(3);
        }      
        else {
            return null;
        }
    }

    updateOptCheckRequestForCR = (updatedList, index, nodeName) => {
        if ((nodeName === "share" || nodeName === "lic_per" || nodeName === "fee") && (this.props?.lic?.share ? this.props?.lic?.share == 0 : true)) {
            this.setState({
                showValidationAlert: true,
                sharePercentageError: "Please add Share for the licensor"
            })
            return false;
        }
        let share, lic, amount;
        if (nodeName === "payee_remit_to") {
            updatedList[index].payee_remit_to_id = updatedList[index].payee_remit_to?.value
        }       
        else if (nodeName === "lic_per") {
            share = this.calculateLicShareAmount(updatedList[index]?.lic_per, null, null);
            amount = this.calculateLicShareAmount(updatedList[index]?.lic_per, share, null);
            lic = updatedList[index]?.lic_per;
            // updatedList[index].share = parseInt(updatedList[index]?.share) / parseInt(updatedList[index]?.lic_per);
        } else if (nodeName === "share") {
            share = updatedList[index]?.share //parseFloat(updatedList[index]?.share)?.toFixed(3);
            lic = this.calculateLicShareAmount(null, share, null);
            amount = this.calculateLicShareAmount(lic, share, null);

        } else {

        }
        if (nodeName === "share" || nodeName === "lic_per") {
            if (parseFloat(share) > parseFloat(this.props.lic?.share)) {
                // updatedList[index].share = null;
                this.setState({ showValidationAlert: true, sharePercentageError: "Share % for this check cannot be more than this Licensor's share." })
                return;
            }
            updatedList[index].lic_per = !isNaN(lic) ? lic : null;
            updatedList[index].share = !isNaN(share) ? share : null;;
            updatedList[index].fee = nodeName !== "fee" ? !isNaN(amount) ? amount : null : updatedList[index]?.fee;

        }
        this.setState({ optionalRightCheckRequestList: updatedList })
        console.log("this.state.selectedOptionalRight",this.state.selectedOptionalRight)
        let optionalRights = this.props.lic?.[this.props?.optionalRightKey]?.length > 0 ? JSON.parse(JSON.stringify(this.props.lic?.[this.props?.optionalRightKey])) : [];
        let optionalRightIndex = optionalRights?.findIndex(item=>item?.id === this.state?.selectedOptionalRight?.id);
        if(optionalRightIndex !== -1){
            optionalRights[optionalRightIndex][this.props?.optionalRightCheckRequestKey] = [...updatedList]
        }
        this.props?.onChangeCallback(this.props?.optionalRightKey, optionalRights);
    }

    handleDefaultValuePopulation = (value, nodeName) => {
        if ((nodeName === "share" || nodeName === "lic_per" || nodeName === "fee") && (this.props?.lic?.share ? this.props?.lic?.share == 0 : true)) {
            this.setState({
                showValidationAlert: true,
                sharePercentageError: "Please add Share for the licensor"
            })
            return false;
        }
        if (nodeName === 'payee_remit_to' && value) {
            let shareValues = [];
            let licValue = {
                node: "lic_per",
                value: 100
            }
            let shareVal = {
                node: "share",
                value: parseInt(this.props.lic?.share)
            }
            let fee = {
                node: "fee",
                value: parseInt(this.state?.selectedOptionsFee)
            }
            shareValues.push(licValue, shareVal, fee);
            return shareValues;

        }
        if (nodeName === 'lic_per') {
            let shareValues = [];
            let licValue = {
                node: "lic_per",
                value: value
            }
            let shareVal = {
                node: "share",
                value: this.calculateLicShareAmount(value, null, null)
            }
            let fee = {
                node: "fee",
                value: this.calculateLicShareAmount(value, shareVal?.value, null)
            }
            shareValues.push(licValue, shareVal, fee);
            return shareValues;
        }
        if (nodeName === 'share') {
            let shareValues = [];
            let licValue = {
                node: "lic_per",
                value: this.calculateLicShareAmount(null, value, null)
            }
            let shareVal = {
                node: "share",
                value: value
            }
            let fee = {
                node: "fee",
                value: this.calculateLicShareAmount(licValue?.value, value, null)
            }
            shareValues.push(licValue, shareVal, fee);
            return shareValues;
        }
        return [];
    }

    handleShowCheckReqForOptions = (listEdit, dataItem) => {
        this.setState({ selectedOptionsFee: null, showAquireValidationCheck: false, showAquireValidationCheckMessage : null,isHVOptionalRight:dataItem?.is_include_home_video === 1?true:false})
        let optionalRights = [...listEdit];
        let itemIndex = null;
        let licOptionalId = null;
        if (this.props?.isMainTitle) {
            itemIndex = optionalRights?.findIndex(item => item?.main_title_licensor_optional_rights_id === dataItem?.main_title_licensor_optional_rights_id)
            licOptionalId = optionalRights[itemIndex]?.main_title_licensor_optional_check_requests?.filter(item => dataItem?.main_title_licensor_optional_check_requests?.find((data) => item?.main_title_licensor_optional_check_requests_id === data?.main_title_licensor_optional_check_requests_id))[0]?.main_title_licensor_id
        } else {
            itemIndex = optionalRights?.findIndex(item => item?.clearance_licensor_optional_rights_id === dataItem?.clearance_licensor_optional_rights_id)
            licOptionalId = optionalRights[itemIndex]?.clearance_licensor_optional_check_requests?.filter(item => dataItem?.clearance_licensor_optional_check_requests?.find((data) => item?.clearance_licensor_optional_check_requests_id === data?.clearance_licensor_optional_check_requests_id))[0]?.clearance_licensor_id
        }
        if (!optionalRights[itemIndex]?.is_option_acquired) {
            this.setState({
                showAquireValidationCheck: true, showAquireValidationCheckMessage : 'This option must be aquired first'
            })
        } else {
            // let checkRequestKey = !this.props?.isMainTitle ? 'clearance_check_request' : 'main_title_check_request';
            // let checkRequestToCopy = this.props?.lic?.[checkRequestKey]?.[0];
            this.setState({ showOptionalRightCheckReqGrid: !this.state.showOptionalRightCheckReqGrid, optionalRightId: optionalRights[itemIndex]?.right_id }, () => {
                this.setState({
                    selectedOptionsFee: this.state.showOptionalRightCheckReqGrid ? dataItem?.fee : null,
                    optionalRightCheckRequestList: this.state.showOptionalRightCheckReqGrid ? optionalRights[itemIndex]?.[this.props?.optionalRightCheckRequestKey] : [],
                    licOptionalId: licOptionalId
                })
            })
        }
    }


    handleOpenLicensorInlineModals = (dataItem, nodeName, rowIndex, event) => {
        console.log("dataItem", dataItem, nodeName, rowIndex, event)
        if (nodeName === "eps_op_cnt") {
            this.setState({
                eps_op_cnt_list: dataItem?.episodes_list_opt,
                setEpsOpCountModal: event?.currentTarget || event,
                epsOpCountFlag: rowIndex
            })
            console.log("setEpsOpCountModal")
        } else {
            this.props?.handleOpenOptionalPayeeInlineModals(dataItem, nodeName, rowIndex, event);
        }
    }

    episodeCount = () => {
        let list = [...this.state.optionalRightCheckRequestList]
        list[this.state.epsOpCountFlag].eps_op_cnt = this.state.epsOpCount;
        list[this.state.epsOpCountFlag].episodes_list_opt = this.state.eps_op_cnt_list?.length > 0 ? this.state.eps_op_cnt_list?.map(item => ({ ...item, ...{ episode_title: item.text, show_season_episode_id: item?.value } })) : [];
        list[this.state.epsOpCountFlag].is_opt_cr_eps_updated = 1;
        this.props?.handleOnChange("main_title_licensor_optional_check_requests", list, this.state.epsOpCountFlag)
        this.props?.isLicensorOptionalRightsUpdated()
        this.setState({ optionalRightCheckRequestList: list, setEpsOpCountModal: null, eps_op_cnt_list: [] })
        // , () => {
        // is_check_request_updated = 1
        // })
    }


    updateOptionalRightCheckRequests = (checkRequestToCopy, optionalRight) => {
        let optionalRightCR = checkRequestToCopy;
        optionalRightCR[this.props?.optionalRightKey + '_id'] = optionalRight?.[this.props?.optionalRightKey];
        optionalRightCR[this.props?.optionalRightCheckRequestKey + '_id'] = null;
        optionalRightCR.fee = optionalRight?.fee;
        optionalRight?.[this.props?.optionalRightCheckRequestKey]?.push(optionalRightCR);
        return optionalRight;
    }

    handleTermTerritoryPopulation = (value, nodeName) => {
        if (nodeName === 'right_id') {
            let rightDetails = this.state.config?.headings?.[0]?.selectOptions?.find(r => r.value === value);
            let termTerritoryValues = [];
            let termValue = {
                node: 'term_id',
                value: rightDetails?.default_term_id || null
            }
            let territoryValue = {
                node: 'territory_id',
                value: rightDetails?.default_territory_id || null
            }
            let optExpTermValue = {
                node: 'opt_exp_term_id',
                value: rightDetails?.default_exp_term_id || null
            }
            termTerritoryValues.push(termValue, territoryValue, optExpTermValue);
            return termTerritoryValues;
        }
        return [];
    }

    handleClickModal = (event) => {
        this.setState({
            setModal: event.currentTarget
        })
    };

    handleCloseModal = () => {
        this.setState({
            setModal: null
        })
    };
    handleOpenBCHModal = (dataItem) => {
        this.setState({
            setBCHModal: true
        })
    }
    closeBCHModal = () => {
        this.setState({
            setBCHModal: false
        })
    }
    handleRefreshAfterAcquisition = (acquiredDate) => {
        let optionalRightKey = !this.props?.isMainTitle ? 'clearance_licensor_optional_rights' : 'main_title_licensor_optional_rights';
        let optionalRights = this.props?.lic?.[optionalRightKey];
        if (!this.props?.isMainTitle) {
            optionalRights?.map(item => {
                if (item?.clearance_licensor_optional_rights_id === this.state.selectedOptionalRight?.clearance_licensor_optional_rights_id) {
                    item.is_option_acquired = 1;
                    item.option_acquired_date = getFormattedDate(acquiredDate);
                    return item;
                }
            })
        } else {
            optionalRights?.map(item => {
                if (item?.main_title_licensor_optional_rights_id === this.state.selectedOptionalRight?.main_title_licensor_optional_rights_id) {
                    item.is_option_acquired = 1;
                    item.option_acquired_date = getFormattedDate(acquiredDate);
                    item.is_licensor_optional_check_requests_updated = 1;
                    return item;
                }
            })
        }
        this.props?.onChangeCallback(optionalRightKey, optionalRights);
    }

    handleEditPayeeModal = (node,dataItem,index) => (e) => {
        this.setState({ openPayeeModal: dataItem?.payee_id })
    }

    getLicensorCueSheetPublisherDetails = () => {
        let url = this.props?.isMainTitle ? `/licensorCueSheetPublisher?main_title_licensor_id=${this.props?.lic?.main_title_licensor_id}` :
                    `/licensorCueSheetPublisher?clearance_licensor_id=${this.props?.lic?.clearance_licensor_id}`
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url,
            this.props?.userContext?.active_tenant?.tenant_id, this.props?.divisionId)
            .then(response => {
                this.props?.onChangeCallback('cue_sheet_publishers', response.data);
            },
                (err) => {
                    console.log("Error in fetching Cue Sheet Publisher Info:", err);
                })
    }

    render() {
        let cuesheetPublisherInfoPermission = this.props?.isMainTitle ? this.props.mainTitlePermissions?.find((per) => this.props.clearanceRecordPermissionsConfig?.find((item) => item.buttonName == "Cue Sheet Publisher Info" && item.permissions.includes(per.permission_name))) : this.props.clearancePermissions?.find((per) => this.props.clearanceRecordPermissionsConfig?.find((item) => item.buttonName == "Cue Sheet Publisher Info" && item.permissions.includes(per.permission_name)))
        let licensorNotesField = this.props?.isMainTitle ? this.props.mainTitlePermissions?.find((per) => this.props.clearanceRecordPermissionsConfig?.find((item) => item.buttonName == "Licensor Notes Field" && item.permissions.includes(per.permission_name))) : this.props.clearancePermissions?.find((per) => this.props.clearanceRecordPermissionsConfig?.find((item) => item.buttonName == "Licensor Notes Field" && item.permissions.includes(per.permission_name)))
        let optionalRightsPermissions = this.props?.isMainTitle ? this.props.mainTitlePermissions?.find((per) => this.props.clearanceRecordPermissionsConfig?.find((item) => item.buttonName == "optionRights" && item.permissions.includes(per.permission_name))) : this.props.clearancePermissions?.find((per) => this.props.clearanceRecordPermissionsConfig?.find((item) => item.buttonName == "optionRights" && item.permissions.includes(per.permission_name)))
        optionalRightsPermissions?.edit == 1 ? this.state.config?.inlineEdits?.map((item) => item.fieldDisabled = false) : null
        let optionalRightsCRPermissions = this.props?.isMainTitle ? this.props.mainTitlePermissions?.find((per) => this.props.clearanceRecordPermissionsConfig?.find((item) => item.buttonName == "optionRightsCheckRequests" && item.permissions.includes(per.permission_name))) : this.props.clearancePermissions?.find((per) => this.props.clearanceRecordPermissionsConfig?.find((item) => item.buttonName == "optionRightsCheckRequests" && item.permissions.includes(per.permission_name)))
        optionalRightsCRPermissions?.edit == 1 ? this.state.CueSheetPublisherConfig?.inlineEdits?.map((item) => item.fieldDisabled = false) : null;
        let optionAcquisitionModalPermissions = this.props?.isMainTitle ? this.props.mainTitlePermissions?.find((per) => this.props.clearanceRecordPermissionsConfig?.find((item) => item.buttonName == "optionAcquisitionModal" && item.permissions.includes(per.permission_name))) : this.props.clearancePermissions?.find((per) => this.props.clearanceRecordPermissionsConfig?.find((item) => item.buttonName == "optionAcquisitionModal" && item.permissions.includes(per.permission_name)))
        let openModal = Boolean(this.state.setModal);
        let idModal = openModal ? 'modal-popover' : undefined;
        let openInvModal = Boolean(this.props?.setOpInvModal);
        let openNotesModal = Boolean(this.props?.setOpNotesModal);
        let openEpsOpCountModal = Boolean(this.state.setEpsOpCountModal);
        let messageModalTitle = this.state.showAquireValidationCheck ? this.state.showAquireValidationCheckMessage :
            this.state.showCannotAcquireAlert ? this.state.cannotAcquireAlertMessage : this.state.showValidationAlert ? this.state.sharePercentageError : null;
        let renderListData = this.state.renderList?.filter(item => item.is_delete != 1)
        return (
            <>
                {(this.state.showAquireValidationCheck || this.state.showCannotAcquireAlert || this.state.showValidationAlert) &&
                    <MessageModal
                        open={(this.state.showAquireValidationCheck || this.state.showCannotAcquireAlert || this.state.showValidationAlert) || false}
                        title={this.state.showValidationAlert ? 'Share Validation' : "Acquire Validation"}
                        message={messageModalTitle}
                        hideCancel={true}
                        primaryButtonText={"OK"}
                        onConfirm={() => this.setState({ showAquireValidationCheck: false, showCannotAcquireAlert: false, showValidationAlert: false })}
                        handleClose={(e) => this.setState({ showAquireValidationCheck: false, showCannotAcquireAlert: false, showValidationAlert: false })}
                    />
                }
                {this.state.isFetchingCueSheetDetails ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                    <div className="cueSheetPublisher-Container" class='row' id='mainGrid'>
                        <div>
                            <MDBContainer className="cuesheetpublisher-container">
                                <MDBRow>
                                    {cuesheetPublisherInfoPermission?.view === 1 &&
                                        <MDBCol md={8}>
                                            <div className="font-cue">
                                                Cue Sheet Publisher Info
                                            </div>
                                            <div className="font-cue-2">
                                                {this.props?.lic?.cue_sheet_publishers?.length > 0 ?
                                                    <>
                                                        {this.props?.lic?.cue_sheet_publishers?.map((item, index) => (
                                                            <div className="d-flex">
                                                                {item[this.props?.isMainTitle ? 'main_title_song_id' : 'clearance_song_id'] === this.props?.selectedSong[this.props?.isMainTitle ? 'main_title_song_id' : 'clearance_song_id'] && <div>
                                                                    <span>{item?.cue_sheet_publisher_name}</span>
                                                                    <span>{item?.affiliation_name ? (' (' + item?.affiliation_name + ')') : ''}</span>
                                                                    <span>{item?.share ? (' ' + item?.share + '%') : ''}</span>
                                                                </div>}
                                                                <div>
                                                                    {(index === 0 && this.props?.lic?.editing && cuesheetPublisherInfoPermission?.edit === 1) ?
                                                                        <span className="cue-icon">
                                                                            <MDBIcon icon="pen"
                                                                                onClick={this.handleEditCueSheetPublisherInfo}
                                                                                handleClose={this.handleHideEditCueSheetPublisherInfo}
                                                                            />
                                                                        </span> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </> :
                                                    // <>{ediCueSheetInfo &&
                                                        <span>{"-"}
                                                            {this.props?.lic?.editing && cuesheetPublisherInfoPermission?.edit === 1 ?
                                                                <span className="cue-icon">
                                                                    <MDBIcon icon="pen"
                                                                        onClick={this.handleEditCueSheetPublisherInfo}
                                                                        handleClose={this.handleHideEditCueSheetPublisherInfo}
                                                                    />
                                                                </span> : null
                                                            }
                                                        </span>
                                                        // }
                                                    // </>
                                                }
                                            </div>
                                        </MDBCol>
                                    } 
                                    {licensorNotesField?.view &&
                                        <MDBCol>
                                            <div className="font-cue">
                                                Notes
                                            </div>
                                            {licensorNotesField?.edit == 1 &&
                                                <div className="font-cue-2">
                                                    {this.props?.lic?.notes || "-"}
                                                    {this.props?.lic?.editing ?
                                                        <>
                                                            <span className="cue-icon"><MDBIcon icon="pen" onClick={this.handleClickModal} /></span>
                                                            {/* <BasicButton icon="arrows-alt-v"
                                                            text=" "
                                                            variant="contained"
                                                            className="arrow_alt_icon"
                                                            onClick={this.handleClickModal}
                                                        /> */}
                                                            <Popover
                                                                id={idModal}
                                                                open={openModal}
                                                                className={"pop-over"}
                                                                anchorEl={this.state.setModal}
                                                                onClose={this.handleCloseModal}
                                                                anchorReference="anchorOrigin"
                                                                anchorOrigin={{
                                                                    horizontal: "right",
                                                                    vertical: "bottom "
                                                                }}
                                                                transformOrigin={{
                                                                    horizontal: "left",
                                                                    vertical: "top"
                                                                }}
                                                                anchorPosition={{ top: 700, left: 300 }}
                                                                PaperProps={{ style: { width: '26%', backgroundColor: '#F2F2F2', borderRadius: '1px' } }}
                                                            >
                                                                <NotesModal
                                                                    field = {"notes"}
                                                                    lic={this.props?.lic}
                                                                    onChangeCallback={this.props?.onChangeCallback.bind(this)}
                                                                    isEditMode={this.props?.lic?.editing || false}
                                                                    onClose={this.handleCloseModal}
                                                                    licensorNotesField={licensorNotesField}
                                                                />
                                                            </Popover>
                                                        </> : null}
                                                </div>
                                            }
                                        </MDBCol>
                                    }
                                </MDBRow>
                            </MDBContainer>
                        </div>
                        {/* {(viewOptionaRightsAndCheckRequest && this.props?.lic?.editing && renderListData?.length == 0 && !this.state?.showOptionalRightGrid ) &&  */}
                        {( this.props?.lic?.editing && renderListData?.length == 0 && !this.state?.showOptionalRightGrid && optionalRightsPermissions?.edit == 1 ) && 
                        <>
                            <BasicButton
                                id={"btnShowOptionalGrid"}
                                text="Optional Rights"
                                icon="plus-circle"
                                onClick={this.toggleOptionalRightGrid.bind(this,true)}
                            ></BasicButton>
                        </>}
                        {(this.state?.showOptionalRightGrid ) &&
                          
                            <>
                                {this.props?.inValidOptionalReq &&
                                    <MDBCol md={12}>
                                        <span className='errorText'>Option, Fee, Term, Territory, Opt. Exp Are Mandatory For All The Records Inside Optional Rights.</span>
                                    </MDBCol>
                                }
                                {optionalRightsPermissions?.view == 1 &&
                                <Accordion onChange={this.handleChange('panel1')} className="cue-boxshadow">
                                    <div className="cueSheet-header">
                                        <div className="cuesheet-btn">
                                            <Typography className="header-cue">
                                                Options
                                            </Typography>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                            </AccordionSummary>
                                        </div>

                                    </div>
                                    <AccordionDetails id="cuesheet-table">
                                        <div >
                                            <MDBRow>
                                                <div id="optional-rights-table-bg" className="optionalRightsTable" >
                                                    <div className="font-cue">
                                                        Optional Rights
                                                    </div>
                                                    {this.state.config?.headings[3].selectOptions?.length > 0 && this.state.config?.headings[0].selectOptions?.length > 0 && (
                                                        <>
                                                     <p className='errorMessage'>{this.state.MandatoryOptionalRightError && `Please enter Rights, fee, term, territory and Opt Exp field's value`}</p>
                                                       <TableComponent
                                                            idForFocus={'licensorOptRightsgrid'}
                                                            list={renderListData || []}
                                                            className={"clearencRecord"}
                                                            config={this.state.config}
                                                            handlepopupCallback={this.handleOptionalRightPopupClick.bind(this)}
                                                            // handleOpenInlineModal={this.handleOpenAquireOption.bind(this)}
                                                            handleAddNewItem={optionalRightsPermissions?.edit == 1 ? this.handleAddNewItem.bind(this) : null}
                                                            handleDelCallBack={optionalRightsPermissions?.delete == 1 ? this.handleDelete.bind(this, "clearance_licensor_optional_rights") : null}
                                                            addItemToList={this.props?.lic?.editing ? true : false}
                                                            flag={true}
                                                            removeMinus={true}
                                                            listUpdated={this.state.optionalRightsListUpdated}
                                                            handleListUpdate={() => this.setState({ optionalRightsListUpdated: false })}
                                                            updatePayeeGrid={this.updatePayeeGrid.bind(this)}
                                                            updateOptCheckRequest={this.updateOptCheckRequest.bind(this)}
                                                            handleOpenInlineModal={(this.props?.isMainTitle && optionAcquisitionModalPermissions?.view == 1) ? this.handleOpenAquireOption.bind(this) : optionAcquisitionModalPermissions?.view == 1 ? this.handleOpenAquireOption.bind(this) : null}
                                                            handleValuePopulation={this.handleTermTerritoryPopulation.bind(this)}
                                                            handleBCHModal={(node, value) => optionalRightsCRPermissions?.view == 1 ? this.handleShowCheckReqForOptions.bind(this, renderListData, value) : null}
                                                            validateNewItem={(newDataItem)=>{  
                                                                if(newDataItem?.right_id && newDataItem?.fee && newDataItem?.term_id && newDataItem?.territory_id && newDataItem?.opt_exp_term_id && validateNumDotOnly(newDataItem?.fee)){
                                                                    this.setState({MandatoryOptionalRightError:false})
                                                                    return true;
                                                                }else{
                                                                    this.setState({MandatoryOptionalRightError:true})
                                                                    return false;
                                                                }
                                                        }}  
                                                        />
                                                        </>
                                                    )}
                                                </div>
                                            </MDBRow>
                                            <MDBRow>
                                                <div className="optionalRightsTable" id="optional-rights-table-bg">
                                                    {this.props?.inValidOptionalChkReq &&
                                                        <><MDBCol md={12}>
                                                            <span className='errorText'>Payee, Lic%, Share, Init.Fee Are Mandatory For All The Records Inside Optional Rights Check Requests.</span>
                                                        </MDBCol>
                                                            <br />
                                                        </>
                                                    }
                                                    <div className="font-cue-year">
                                                        {this.state.showOptionalRightCheckReqGrid && this.state.config?.headings[0].selectOptions?.find(item => item.value === this.state.selectedOptionalRight?.right_id)?.label}
                                                    </div>
                                                    {this.state.showOptionalRightCheckReqGrid && this.state.CueSheetPublisherConfig?.headings[1].selectOptions?.length > 0 && (
                                                        <div className="optional-rights-payee-table">
                                                            <div className={this.props?.isMainTitle ? "" : "episodeClearanceButtons"} >
                                                            <p className='errorMessage'>{this.state.MandatoryOptionalRightChkRequestError && `Please enter payee, Lic %, share % and init fee field's value`}</p>
                                                                <TableComponent
                                                                    getPayeeData={this.getPayeeData?.bind(this)}
                                                                    allPayeeRemitTo={this.state?.allPayeeRemitTo}
                                                                    idForFocus={'licensorOptRightsCRgrid'}
                                                                    list={this.state.optionalRightCheckRequestList || []}
                                                                    className={"optionalRightCheckRequest"}
                                                                    config={this.state.CueSheetPublisherConfig}
                                                                    // config={this.props?.isMainTitle ? this.state.CueSheetPublisherMainTitleConfig : this.state.CueSheetPublisherConfig}
                                                                    handlepopupCallback={this.handleOpCheckRequestPopupClick.bind(this)}
                                                                    handleOpenInlineModal={this.handleOpenLicensorInlineModals}
                                                                    // handleOpenInlineModal={this.props?.handleOpenOptionalPayeeInlineModals}
                                                                    handleAddNewItem={optionalRightsCRPermissions?.edit == 1 ? this.handleAddNewItemPayees.bind(this) : null}
                                                                    handleDelCallBack={optionalRightsCRPermissions?.delete == 1 ? this.handleDelete.bind(this, "clearance_licensor_optional_check_requests") : null}
                                                                    addItemToList={this.props?.lic?.editing ? true : false}
                                                                    removeMinus={true}
                                                                    listUpdated={this.state.optionalPayeeListUpdated}
                                                                    handleListUpdate={() => this.setState({ optionalPayeeListUpdated: false })}
                                                                    //handleShowPayeeModal={this.handleShowPayeeModal}
                                                                    updateOptCheckRequest={this.updateOptCheckRequestForCR.bind(this)}
                                                                    handleBCHModal={(e, value) => this.props?.clearanceBatchesPermissions?.edit == 1 ? this.handleOpenBCHModal.bind(this, value) : null}
                                                                    handleValuePopulation={this.handleDefaultValuePopulation.bind(this)}
                                                                    defaultValuePopulation={this.state?.defaultPayee || null}
                                                                    handleEditPayeeModal={(e, value, index) => {this.handleEditPayeeModal(value, index)}}
                                                                    validateNewItem={(newDataItem)=>{  
                                                                        if(newDataItem?.payee_remit_to?.value && newDataItem?.lic_per && newDataItem?.share && newDataItem?.fee && validateNumDotWithNegative(newDataItem?.lic_per) && validateNumDotWithNegative(newDataItem?.share) && validateNumDotWithNegative(newDataItem?.fee)){
                                                                            this.setState({MandatoryOptionalRightChkRequestError:false})
                                                                            return true;
                                                                        }else{
                                                                            this.setState({MandatoryOptionalRightChkRequestError:true})
                                                                            return false;
                                                                        }
                                                                }}   
                                                                />
                                                                <Popover
                                                                    open={openInvModal}
                                                                    className="flag-popover"
                                                                    anchorEl={this.props?.setOpInvModal}
                                                                    onClose={this.props?.handleCloseOpInvModal}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left'
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left'
                                                                    }}
                                                                    PaperProps={{
                                                                        style:
                                                                            { width: '15%', backgroundColor: '#F2F2F2', borderRadius: '1px' }
                                                                    }}
                                                                >
                                                                    <NotesModal
                                                                        notesValue={this.props?.optionalPayeeInv?.inv}
                                                                        field={"op_inv_notes"}
                                                                        onChangeCallback={this.props?.handleOnChange.bind(this)}
                                                                        isEditMode={this.props?.lic?.editing}
                                                                        onClose={this.props?.handleCloseOpInvModal}
                                                                        optionalRightsCRPermissions={optionalRightsCRPermissions}
                                                                    />
                                                                </Popover>
                                                                <Popover
                                                                    open={openEpsOpCountModal}
                                                                    className="flag-popover"
                                                                    style={{
                                                                        width: '1800px',
                                                                        // height: '200px'
                                                                    }}
                                                                    anchorEl={this.state.setEpsOpCountModal}
                                                                    onClose={() => {
                                                                        this.setState({
                                                                            setEpsOpCountModal: null
                                                                        })
                                                                    }}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left'
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left'
                                                                    }}

                                                                >
                                                                    <div >
                                                                        <div style={{ fontSize: '0.65rem', fontWeight: 600 }}>Select the Episodes this check is paying for </div>
                                                                        <div className={"eps-height"}>
                                                                            <SearchSelectField
                                                                                placeHolderText='-Select Episodes-'
                                                                                disabled={this.props?.lic?.editing ? false : true}
                                                                                // disabled={this.state.lic?.editing ? false : true}
                                                                                multiple={true}
                                                                                value={this.state.eps_op_cnt_list || []}
                                                                                options={this.props?.episodeBasedOptions || []}
                                                                                onChange={(e, value) => this.onChangeCallback('eps_op_cnt_list', value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <MDBRow>
                                                                        <MDBCol md={8}></MDBCol>
                                                                        <MDBCol md={3}>
                                                                            <MDBRow >
                                                                                <MDBCol className="display-modal-2">
                                                                                    <div> <BasicButton
                                                                                        className={"modal-btn-save"}
                                                                                        disabled={this.props?.lic?.editing ? false : true}
                                                                                        variant="contained"
                                                                                        text=" "
                                                                                        icon={"save"}
                                                                                        onClick={this.episodeCount}
                                                                                    /></div>
                                                                                    <div>
                                                                                        <BasicButton
                                                                                            className={"modal-btn"}
                                                                                            variant="outlined"
                                                                                            text=" "
                                                                                            icon={"times"}
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    setEpsOpCountModal: null
                                                                                                })
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </MDBCol>
                                                                            </MDBRow>
                                                                        </MDBCol>
                                                                    </MDBRow>
                                                                </Popover>
                                                                <Popover
                                                                    open={openNotesModal}
                                                                    className="flag-popover"
                                                                    anchorEl={this.props?.setOpNotesModal}
                                                                    onClose={this.props?.handleCloseOpNotesModal}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left'
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left'
                                                                    }}
                                                                    PaperProps={{
                                                                        style:
                                                                            { width: '15%', backgroundColor: '#F2F2F2', borderRadius: '1px' }
                                                                    }}
                                                                >
                                                                    <NotesModal
                                                                        notesValue={this.props?.optionalPayeeNotes?.notes}
                                                                        field={"op_notes"}
                                                                        onChangeCallback={this.props?.handleOnChange.bind(this)}
                                                                        isEditMode={this.props?.lic?.editing}
                                                                        onClose={this.props?.handleCloseOpNotesModal}
                                                                        optionalRightsCRPermissions={optionalRightsCRPermissions}
                                                                    />
                                                                </Popover>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </MDBRow>
                                            {this.state.setCheckRequestPopUp ?
                                                <CheckRequestPopUp
                                                    checkRequestAnchorEl={this.state.setCheckRequestPopUp}
                                                    listName={!this.props?.isMainTitle ?
                                                        "clearance_licensor_optional_rights" :
                                                        "main_title_licensor_optional_rights"
                                                    }
                                                    userName={this.props?.userContext?.user_profile?.full_name || ''}
                                                    selectedCheckRequest={this.state.selectedCheckRequest}
                                                    onChangeCallback={this.handleCRPopUpOnChange}
                                                    isEditing={this.props?.lic?.editing || false}
                                                    open={this.state.setCheckRequestPopUp}
                                                    handleVoidCheck={this.props?.handleVoidCheck}
                                                    handleOnClose={() => this.setState({ setCheckRequestPopUp: false , selectedCheckRequest : null})}
                                                    initialRightsCheckRequestsPermission={optionalRightsCRPermissions}
                                                /> :
                                                <CheckRequestPopUp
                                                    opCheckRequestAnchorEl={this.state.setOpCheckRequestPopUp}
                                                    listName={!this.props?.isMainTitle ?
                                                        "clearance_licensor_optional_rights" :
                                                        "main_title_licensor_optional_rights"
                                                    }
                                                    userName={this.props?.userContext?.user_profile?.full_name || ''}
                                                    selectedOptionalRight={this.state.selectedOptionalRightForExpand}
                                                    onChangeCallback={this.handleOpPopUpOnChange}
                                                    isEditing={this.props?.lic?.editing || false}
                                                    openOpPopUp={this.state.setOpCheckRequestPopUp}
                                                    handleOnClose={() => this.setState({ setOpCheckRequestPopUp: false , selectedOptionalRightForExpand : null})}
                                                    initialRightsCheckRequestsPermission={optionalRightsPermissions}
                                                />
                                            }

                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                }
                            </>
                        }
                        {
                            this.state.openPayeeModal &&
                            <MainViewModalComponent
                                open={this.state.openPayeeModal ? true : false}
                                handleClose={() => this.setState({ openPayeeModal: null })}
                                headerText={"Payee"}
                                mode={"Edit"}
                                modalName={"Payee"}
                                id={this.state.openPayeeModal}
                                commonPermissions={this.props?.userContext?.active_tenant?.common_permissions?.filter(item => item.permission_name === "Clearance Payees")}
                            />
                        }
                        {this.state.setBCHModal &&
                            <MainViewModalComponent
                                open={this.props?.clearanceBatchesPermissions?.view ==1 ? this.state.setBCHModal : false}
                                handleClose={this.closeBCHModal}
                                headerText={"Batches"}
                                mode={""}
                                isMainTitle={this.props?.isMainTitle}
                                modalName={"MainTitle Batches"}
                                // show_season_episode_id={this.state.showSeasonEpisodeId}
                                show_season_id={this.props?.show_season_id}
                                right_id={this.state.optionalRightId}
                                main_title_id={this.props?.main_title_id}
                                licOptionalId={this.state.licOptionalId}
                                clearanceBatchesPermissions={this.props?.clearanceBatchesPermissions}
                                divisionId={this.props?.divisionId}
                                isHVOptionalRight={this.state.isHVOptionalRight}
                            />}
                        {
                            this.state.EditCueSheetPublisherInfo &&
                            <MainViewModalComponent
                                open={this.state.EditCueSheetPublisherInfo}
                                handleClose={this.handleHideEditCueSheetPublisherInfo}
                                headerText={"Edit Cue Sheet Publisher Info"}
                                mode={""}
                                modalName={"Edit Cue Sheet Publisher Info"}
                                selectedSong={this.props?.selectedSong}
                                isMainTitle={this.props?.isMainTitle}
                                clearneceLicenserInfo={this.props.clearneceLicenserInfo}
                                licensorsInfo={this.props?.licensorsInfo}
                                lic={this.props.lic}
                                divisionId={this.props?.divisionId}
                                toggleRefreshCueSheetPublisherDetails={this.getLicensorCueSheetPublisherDetails}
                            />
                        }
                        {
                            this.props?.isMainTitle ?
                                this.state.OpenMainTitleInlineModal &&
                                <MainViewModalComponent
                                    open={this.state.OpenMainTitleInlineModal}
                                    handleClose={this.handleHideMainTitleInlineModal}
                                    headerText={"Acquire Option"}
                                    mode={""}
                                    showName={this.props?.showName}
                                    seasonName={this.props?.seasonName}
                                    episodeNumber={this.props?.episodeNumber}
                                    modalName={"Acquire Option Main Title"}
                                    id={"AcquireOptionId"}
                                    divisionId={this.props?.divisionId}
                                    lic={this.props.lic}
                                    selectedOptionalRight={this.state.selectedOptionalRight}
                                    isMainTitle={this.props.isMainTitle}
                                    song_id={this.props?.licensorsInfo[0].songs_list[0].song_id}
                                    viewAcquireOptionRights={optionAcquisitionModalPermissions}
                                    isEditing={this.props?.lic?.editing || false}
                                    toggleRefreshPage={this.props?.toggleRefreshPage}
                                    toggleRefreshAcquisition={this.handleRefreshAfterAcquisition}                                    
                                />
                                :
                                this.state.showAcquireOptionModal &&
                                <MainViewModalComponent
                                    open={this.state.showAcquireOptionModal}
                                    handleClose={() => this.setState({ showAcquireOptionModal: false })}
                                    headerText={"Acquire Option"}
                                    mode={""}
                                    modalName={"Acquire Option"}
                                    id={"AcquireOptionId"}
                                    showName={this.props?.showName}
                                    seasonName={this.props?.seasonName}
                                    episodeNumber={this.props?.episodeNumber}
                                    clearneceLicenserInfo={this.props.clearneceLicenserInfo}
                                    licensorsInfo={this.props.licensorsInfo}
                                    lic={this.props.lic}
                                    isMainTitle={this.props.isMainTitle}
                                    selectedOptionalRight={this.state.selectedOptionalRight}
                                    song_id={this.props?.song_id}
                                    divisionId={this.props?.divisionId}
                                    showSeasonId={this.props?.show_season_id}
                                    toggleRefresh={this.handleRefreshAfterAcquisition}
                                    // viewAcquireOptionRights={viewAcquireOptionRights}
                                    isEditing={this.props?.lic?.editing || false}
                                    toggleRefreshPage={this.props?.toggleRefreshPage}
                                    optionAcquisitionModalPermissions={optionAcquisitionModalPermissions}
                                />
                        }
                    </div>
                )
                }
            </>
        );
    }
});