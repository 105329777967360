import React, { Component } from 'react'
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel'
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField"
import SelectField from "../../SharedComponents/SelectField/SelectField"
import DateField from "../../SharedComponents/DateField/DateField"
import TimePicker from 'rc-time-picker';
import { ClearTrackServiceBaseUrl } from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';
import * as Constants from '../../../constants/constants';
import { options, filterConfig, songLibraryFilter } from './Config';
import SearchSelectField from '../../SharedComponents/SearchSelectField';
import axios from 'axios';

export default withUserContext(class SongLibrary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterSearchList: [],
            songLibraryFilters: JSON.parse(JSON.stringify({ ...songLibraryFilter })),
            searchCancelToken: null,
            clearFilters: this.props?.clearFilters,
            sortBy: "Show/Season",
            selectedShowForSearch: null,
            filterList: {
                songLibraryFilters: this.props?.songLibraryFilters,
                licensorOptions: [],
                showOptions: [],
                publicDomainOptions: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }],
                seasonOptions: [],
                otherOptions: options,
                showBasedSeasonList: [],
                MusicTypeOptions: []
            }
        }
    }

    componentDidMount() {
        document.title = 'Songs'
        if(this.props?.userContext?.active_tenant?.tenant_id) {
        this.getEntity("SEASON", filterConfig.season, "seasonOptions");
        this.getEntity("MUSICTYPE", filterConfig.musictype, "MusicTypeOptions");
        this.getShows("SHOW", filterConfig.show, "showOptions", "");
        }
        //this.getPublicDomain();
        // this.getLicensorDetails("LICENSOR", filterConfig.licensor, "licensorOptions", null);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.userContext?.active_tenant?.tenant_id != this.props?.userContext?.active_tenant?.tenant_id) {
            // this.getLicensorDetails("LICENSOR", filterConfig.licensor, "licensorOptions", null);
            this.getEntity("SEASON", filterConfig.season, "seasonOptions");
            this.getEntity("MUSICTYPE", filterConfig.musictype, "MusicTypeOptions");
            this.getShows("SHOW", filterConfig.show, "showOptions", "");
        }
        if (this.props?.clearFilters != this.state.clearFilters) {
            let mockClear = this.props?.clearFilters
            this.setState({ clearFilters: mockClear, filterSearchList: [], sortBy: "Show/Season" })
        }
        if (this.props?.userContext.dashboardName === "Songs" && this.state.sortBy != this.props?.sortBy) {
            this.setState({ sortBy: this.props?.sortBy })
        }
    }

    getEntity = (entity, config, node) => {
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=null`, this.props.userContext.active_tenant.tenant_id)
                .then(response => {
                    let formattedList = response.data?.map((item) => ({
                        value: item[config.id],
                        label: item[config.name]
                    }));
                    formattedList.unshift({ label: "Select", value: null })
                    this.updateListState(formattedList, node, "filterList");
                },
                    (err) => {
                        console.log("Error in fetching license data:", err)
                    })
        }
    }



    getShows = (entity, config, node, searchString) => {
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            let option = {
                "searchString": searchString,
            }
            if (this.state.searchCancelToken != null)
                this.state.searchCancelToken.cancel("Operation canceled due to new request");
            var cancelToken = axios.CancelToken.source();
            this.setState({ searchCancelToken: cancelToken });
            ClearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${searchString}`, cancelToken, this.props.userContext.active_tenant.tenant_id, null)
                .then(response => {
                    let formattedList = response.data?.map((item) => ({
                        value: item[config.id],
                        text: item[config.name]
                    }));
                    this.updateListState(formattedList, node, "filterList");
                },
                    (err) => {
                        console.log("Error in fetching license data:", err)
                    })
        }
    }
    selectedShowBasedSeason = (newValue) => {
        this.props.updateListState(
            null,
            "season",
            "songLibraryFilters"
        );
        this.handleFilterListSelection(
            "season",
            null,
            null,
            "season"
        );
        ClearTrackService
            .getData(
                Constants.ClearTrackServiceBaseUrl +
                `/seasonsBasedOnShows?show_id=${newValue.value}`,
                this.props?.userContext?.active_tenant?.tenant_id
            ).then(
                (response) => {
                    let selectedShow = response?.data[0]?.seasons?.find(
                        (item) => response?.data[0]?.show_id === newValue.value
                    )?.show_season_id;
                    let selectedShowSeasons = response?.data[0]?.seasons?.length > 0 ? response?.data[0]?.seasons?.filter(
                        (item, index, data) =>
                            index === data.findIndex((t) => t.season_name === item.season_name && item.show_season_id !== null)
                    ) : [];
                    let formattedList = selectedShowSeasons?.length > 0 ? selectedShowSeasons?.map((item) => ({
                        value: item.season_id,
                        label: item.season_name,
                    })) : [];
                    let seasonBasedEpisodes = response?.data[0]?.seasons;
                    this.setState({ seasonBasedEpisodes })
                    formattedList.unshift({ label: "Select", value: null })
                    if (typeof (selectedShow) !== 'undefined') {
                        this.updateListState(formattedList, "showBasedSeasonList", 'filterList')
                    } else {
                        this.updateListState(formattedList.length > 0 ? formattedList : [], 'showBasedSeasonList', 'filterList')
                    }
                })
            .catch(error => {
                console.log("error--", error)
            })

    }

    getLicensorDetails = (entity, config, node, searchString) => {
        let option = {
            "searchString": searchString
        }
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        ClearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${searchString}`, cancelToken, this.props.userContext.active_tenant.tenant_id, null)
            .then(response => {
                let formattedList = response.data?.map((item) => ({
                    value: item[config.id],
                    text: item[config.name],
                }));
                this.updateListState(formattedList, node, "filterList");
            },
                (err) => {
                    console.log("Error in fetching license data:", err)
                })

    }

    // getPublicDomain = () => {
    //     ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=lu_public_domain_genre`, this.props.userContext?.active_tenant?.tenant_id)
    //         .then(response => {
    //             let formattedList = response.data.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
    //             formattedList.unshift({ label: "Select", value: null })
    //             this.updateListState(formattedList, "publicDomainOptions", 'filterList')
    //         },
    //             (err) => {
    //                 console.log("Error in fetching Public domain data:", err)
    //             })
    // }

    updateListState = (value, node, list) => {
        this.setState(prevState => ({
            [list]: {
                ...prevState[list],
                [node]: value
            }
        }))
    }

    handleFilterListSelection = (type, val, name, node) => {
        let filteredSongs = [...this.state.filterSearchList]
        let newFilterValue = {
            type: type,
            label: name,
            value: val,
            node: node
        }
        let check = filteredSongs.filter((item, index) => {
            if (item.type === type) {
                return filteredSongs[index] = newFilterValue
            }
        })
        if (check.length === 0) {
            filteredSongs.push(newFilterValue)
        }
        this.setState({ filterSearchList: filteredSongs })
        let selectedSongFilters = {
            song_search_text: filteredSongs.find((item) => item.type === "title")?.label || null,
            writer_search_text: filteredSongs.find((item) => item.type === "writer")?.label || null,
            performer_search_text: filteredSongs.find((item) => item.type === "performer")?.label || null,
            sync_lic_search_ids: filteredSongs.find((item) => item.type === "syncLicensor")?.value || null,
            master_lic_search_ids: filteredSongs.find((item) => item.type === "masterLicensor")?.value || null,
            cue_info_search_text: filteredSongs.find((item) => item.type === "cueSheetInfo")?.label || null,
            do_not_use_flag: filteredSongs.find((item) => item.type === "doNotUse")?.value || null,
            is_studio_owned_flag: filteredSongs.find((item) => item.type === "studioOwned")?.value || null,
            is_library_flag: filteredSongs.find((item) => item.type === "library")?.value || null,
            is_coa_flag: filteredSongs.find((item) => item.type === "coa")?.value || null,
            is_contains_sample_flag: filteredSongs.find((item) => item.type === "containsSample")?.value || null,
            is_pd_flag: filteredSongs.find((item) => item.type === "publicDomain")?.value || null,
            public_domain_genre_id: filteredSongs.find((item) => item.type === "publicDomainGenre")?.value || null,
            is_arrangement_flag: filteredSongs.find((item) => item.type === "isArrangement")?.value || null,
            promo_use_flag: filteredSongs.find((item) => item.type === "promoUse")?.value || null,
            show_search_ids: filteredSongs.find((item) => item.type === "show")?.value || null,
            season_search_ids: filteredSongs.find((item) => item.type === "season")?.value || null,
            episode_title_search_text: filteredSongs.find((item) => item.type === "episodeName")?.value || null,
            episode_no_search_text: filteredSongs.find((item) => item.type === "episode")?.label || null,
            show_year_search_text: filteredSongs.find((item) => item.type === "showYr")?.label || null,
            order_by: null,
            order_by_clause: this.state.sortBy,
            page_count: this.props?.rowsPerPage,
            page_no: this.props?.page > 0 ? this.props?.page : 1
        }
        this.props.updateFilteredList(filteredSongs, selectedSongFilters)
    }

    getShowBasedEpisodes = (showSeasonId) => {
        this.setState({ isFetchingEpisodes: true });
        ClearTrackService
            .getData(
                Constants.ClearTrackServiceBaseUrl +
                `/showSeasonEpisodes?show_season_id=${showSeasonId}`,
                this.props.userContext?.active_tenant?.tenant_id,
                1
            )
            .then(
                (response) => {
                    let formattedList = response.data?.map((item) => ({
                        value: item.show_season_episode_id,
                        label: `${item.episode_number ? item.episode_number : ''}${item.episode_title ? '-' + item.episode_title : ''}`,
                    }));
                    formattedList.unshift({ label: "Select", value: null })
                    if (typeof (selectedShow) !== 'undefined') {
                        this.updateListState(formattedList, "seasonBasedEpisodeList", 'filterList')
                    } else {
                        this.updateListState(formattedList.length > 0 ? formattedList : [], 'seasonBasedEpisodeList', 'filterList')
                    }
                },
                (err) => {
                    console.log("Error in fetching Details:", err);
                }
            );
    };

    render() {
        let { songFields } = this.props;
        return (
            <div className='filterFieldsContainer'>
                <MDBRow>
                    <MDBCol md={9}>
                        <MDBRow>
                            <MDBCol md={12}>
                                <h6>Songs</h6>
                            </MDBCol>
                            <MDBCol md={3} className="lh-1 m-t2">
                                <BasicTextField
                                    id="title"
                                    placeholder="Title"
                                    value={this.props?.songLibraryFilters?.title || ""}
                                    onChange={(e) => {
                                        this.handleFilterListSelection("title", "title", e.target.value, "title")
                                        this.props.updateListState(e.target.value, 'title', 'songLibraryFilters')
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={3} className="m-t2">
                                <SearchSelectField
                                    id="syncLicensor"
                                    placeholder="- SyncLicensor -"
                                    width="100%"
                                    searchSelect={true}
                                    multiple={false}
                                    detail_selected={this.props?.songLibraryFilters?.syncLicensor ? {
                                        value: this.props?.songLibraryFilters?.syncLicensor,
                                        text: this.state.filterList?.licensorOptions.find(item => item.value === this.props?.songLibraryFilters?.syncLicensor)?.text
                                    } : null}
                                    options={this.state.filterList?.licensorOptions || []}
                                    valueSelected={(e, newValue) => {
                                        let selectedShow = this.state.filterList?.licensorOptions?.find(item => item.value === (newValue != null ? newValue.value : null));
                                        this.props.updateListState(newValue != null ? newValue.value : null, 'syncLicensor', 'songLibraryFilters')
                                        this.handleFilterListSelection("syncLicensor", (newValue != null ? selectedShow?.value : null), newValue != null ? selectedShow?.text : null, "syncLicensor")
                                    }}
                                    searchText={ev => {
                                        this.getLicensorDetails("LICENSOR", filterConfig.licensor, "licensorOptions", ev.target.value);
                                    }}

                                />
                            </MDBCol>
                            <MDBCol md={2} className="lh-1 m-t2">
                                <SelectField
                                    id="doNotuse"
                                    value={this.props.songLibraryFilters?.doNotUse}
                                    placeHolderText="- Do Not Use -"
                                    options={this.state.filterList?.otherOptions || []}
                                    onChange={(e) => {
                                        let selectedUseValue = this.state.filterList?.otherOptions?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'doNotUse', 'songLibraryFilters')
                                        this.handleFilterListSelection("doNotUse", selectedUseValue?.value, selectedUseValue?.label, "doNotUse")
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={2} className="lh-1 m-t2">
                                <SelectField
                                    id="coa"
                                    value={this.props.songLibraryFilters?.coa || null}
                                    placeHolderText="- COA -"
                                    options={this.state.filterList?.otherOptions || []}
                                    onChange={(e) => {
                                        let selectedCOAValue = this.state.filterList?.otherOptions?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'coa', 'songLibraryFilters')
                                        this.handleFilterListSelection("coa", selectedCOAValue?.value, selectedCOAValue?.label, "coa")
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={2} className="lh-1 m-t2">
                                <SelectField
                                    id="inArrangements"
                                    value={this.props.songLibraryFilters?.isArrangement || null}
                                    placeHolderText="- InArrangements -"
                                    options={this.state.filterList?.otherOptions || []}
                                    onChange={(e) => {
                                        let selectedArrangementsValue = this.state.filterList?.otherOptions?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'isArrangement', 'songLibraryFilters')
                                        this.handleFilterListSelection("isArrangement", selectedArrangementsValue?.value, selectedArrangementsValue?.label, "isArrangement")
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="lh-1">
                            <MDBCol md={3}>
                                <BasicTextField
                                    id="writers"
                                    placeholder="Writers"
                                    value={this.props?.songLibraryFilters?.writer || ''}
                                    onChange={(e) => {
                                        this.handleFilterListSelection("writer", "writer", e.target.value, "writer")
                                        this.props.updateListState(e.target.value, 'writer', 'songLibraryFilters')
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={3} className="lh-1">
                                <SearchSelectField
                                    id="masterlicensor"
                                    placeholder="- Master Licensor -"
                                    width="100%"
                                    searchSelect={true}
                                    multiple={false}
                                    detail_selected={this.props?.songLibraryFilters?.masterLicensor ? {
                                        value: this.props?.songLibraryFilters?.masterLicensor,
                                        text: this.state.filterList?.licensorOptions.find(item => item.value === this.props?.songLibraryFilters?.masterLicensor)?.text
                                    } : null}
                                    options={this.state.filterList?.licensorOptions || []}
                                    valueSelected={(e, newValue) => {
                                        let selectedShow = this.state.filterList?.licensorOptions?.find(item => item.value === (newValue != null ? newValue.value : null));
                                        this.props.updateListState(newValue != null ? newValue.value : null, 'masterLicensor', 'songLibraryFilters')
                                        this.handleFilterListSelection("masterLicensor", (newValue != null ? selectedShow?.value : null), newValue != null ? selectedShow?.text : null, "masterLicensor")
                                    }}
                                    searchText={ev => {
                                        this.getLicensorDetails("LICENSOR", filterConfig.licensor, "licensorOptions", ev.target.value);
                                    }}

                                />
                            </MDBCol>
                            <MDBCol md={2} className="lh-1">
                                <SelectField
                                    id="studioOwned"
                                    value={this.props?.songLibraryFilters?.studioOwned || null}
                                    placeHolderText="- Studio Owned -"
                                    options={this.state.filterList?.otherOptions || []}
                                    onChange={(e) => {
                                        let selectedStudio = this.state.filterList?.otherOptions?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'studioOwned', 'songLibraryFilters')
                                        this.handleFilterListSelection("studioOwned", selectedStudio?.value, selectedStudio?.label, "studioOwned")
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={2} className="lh-1">
                                <SelectField
                                    id="containsSample"
                                    value={this.props?.songLibraryFilters?.containsSample || null}
                                    placeHolderText="- Contains Sample -"
                                    options={this.state.filterList?.otherOptions || []}
                                    onChange={(e) => {
                                        let selectedSample = this.state.filterList?.otherOptions?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'containsSample', 'songLibraryFilters')
                                        this.handleFilterListSelection("containsSample", selectedSample?.value, selectedSample?.label, "containsSample")
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={2} className="lh-1">
                                <SelectField
                                    id="promoUsed"
                                    value={this.props?.songLibraryFilters?.promoUse || null}
                                    placeHolderText="- Promo Used -"
                                    options={this.state.filterList?.otherOptions || []}
                                    onChange={(e) => {
                                        let selectedPromo = this.state.filterList?.otherOptions?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'promoUse', 'songLibraryFilters')
                                        this.handleFilterListSelection("promoUse", selectedPromo?.value, selectedPromo?.label, "promoUse")
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="lh-1">

                            <MDBCol md={3}>
                                <BasicTextField
                                    id="performer"
                                    placeholder="Performer"
                                    value={this.props?.songLibraryFilters?.performer || ''}
                                    onChange={(e) => {
                                        this.handleFilterListSelection("performer", "performer", e.target.value, "performer")
                                        this.props.updateListState(e.target.value, 'performer', 'songLibraryFilters')
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={3} className="lh-1">
                                <BasicTextField
                                    id="cueSheetInfo"
                                    placeholder="Cuesheet info"
                                    value={this.props?.songLibraryFilters?.cueSheetInfo || ''}
                                    onChange={(e) => {
                                        this.handleFilterListSelection("cueSheetInfo", "cueSheetInfo", e.target.value, "cueSheetInfo")
                                        this.props.updateListState(e.target.value, 'cueSheetInfo', 'songLibraryFilters')
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={2} className="lh-1">
                                <SelectField
                                    id="Library"
                                    value={this.props?.songLibraryFilters?.library}
                                    placeHolderText="- Library -"
                                    options={this.state.filterList?.otherOptions || []}
                                    onChange={(e) => {
                                        let selectedLibrary = this.state.filterList?.otherOptions?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'library', 'songLibraryFilters')
                                        this.handleFilterListSelection("library", selectedLibrary?.value, selectedLibrary?.label, "library")
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={2} className="lh-1">
                                <SelectField
                                    id="publicDomain"
                                    value={this.props?.songLibraryFilters?.publicDomain}
                                    placeHolderText={"- Public Domain -"}
                                    options={this.state.filterList?.publicDomainOptions || []}
                                    onChange={(e) => {
                                        let selectedDomain = this.state.filterList?.publicDomainOptions?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'publicDomain', 'songLibraryFilters')
                                        this.handleFilterListSelection("publicDomain", selectedDomain?.value, selectedDomain?.label, "publicDomain")
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                        
                        <MDBRow className="lh-1">
                            <MDBCol md={3} className="lh-1">
                                <SelectField
                                        id="publicDomainGenre"
                                        value={this.props?.songLibraryFilters?.publicDomainGenre}
                                        placeHolderText={"- Music Type -"}
                                        options={this.state.filterList?.MusicTypeOptions || []}
                                        onChange={(e) => {
                                            let selectedMusicType = this.state.filterList?.MusicTypeOptions?.find(item => item.value === e.target.value);
                                            this.props.updateListState(e.target.value, 'publicDomainGenre', 'songLibraryFilters')
                                            this.handleFilterListSelection("publicDomainGenre", selectedMusicType?.value, selectedMusicType?.label, "publicDomainGenre")
                                        }}

                                />
                            </MDBCol>
                        </MDBRow>
                        
                    </MDBCol>
                    <MDBCol md={3}>
                        <MDBRow>
                            <MDBCol md={12}>
                                <h6>Show</h6>
                            </MDBCol>
                            <MDBCol md={12} className="m-t2">
                                <SearchSelectField
                                    id="show"
                                    placeholder="- Show -"
                                    width="100%"
                                    searchSelect={true}
                                    multiple={false}
                                    detail_selected={this.props?.songLibraryFilters?.show ? this.state.selectedShowForSearch : null}
                                    options={this.state.filterList?.showOptions || []}
                                    valueSelected={(e, newValue) => {
                                        if (newValue) {
                                            this.setState({ selectedShowForSearch: newValue })
                                            this.selectedShowBasedSeason(newValue)
                                            //let selectedShow = this.state.filterList?.showOptions?.find(item => item.value === (newValue != null ? newValue.value : null));
                                            this.props.updateListState(newValue != null ? newValue.value : null, 'show', 'songLibraryFilters')
                                            this.handleFilterListSelection("show", (newValue != null ? newValue?.value : null), newValue != null ? newValue?.text : null, "show")

                                        } else {
                                            this.setState({ selectedShowForSearch: null }, () => {
                                                if (!this.state.selectedShowForSearch) {
                                                    this.props.updateListState(null, 'season', 'songLibraryFilters')
                                                    this.handleFilterListSelection('season', null, null, 'season')
                                                }
                                            })
                                            this.props.updateListState(null, 'show', 'songLibraryFilters')
                                            this.handleFilterListSelection("show", null, null, "show")
                                        }
                                    }}
                                    searchText={ev => {
                                        // dont fire API if the user delete or not entered anything
                                        if (ev.target.value !== "" || ev.target.value !== null) {
                                            this.getShows("SHOW", filterConfig.show, "showOptions", ev.target.value);
                                        }
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="lh-1">
                            <MDBCol md={6}>
                                <SelectField
                                    id="Season"
                                    value={this.props?.songLibraryFilters?.season}
                                    placeHolderText="- Season -"
                                    options={this.props?.songLibraryFilters?.show ? this.state.filterList?.showBasedSeasonList : this.state.filterList?.seasonOptions || []}
                                    onChange={(e) => {
                                        let selectedSeason = (this.props?.songLibraryFilters?.show ? this.state.filterList?.showBasedSeasonList : this.state.filterList?.seasonOptions)?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'season', 'songLibraryFilters')
                                        this.handleFilterListSelection("season", selectedSeason?.value, selectedSeason?.label, "season")
                                        let selectedShowSeason =
                                            this.state.seasonBasedEpisodes?.find(
                                                (item) => item.season_id === e.target.value
                                            )?.show_season_id;
                                        this.setState(
                                            { show_season_id: selectedShowSeason },
                                            () => {
                                                this.getShowBasedEpisodes(this.state.show_season_id);
                                            }
                                        );
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={4}>
                                <BasicTextField
                                    id="Showyr"
                                    placeholder="Show Yr."
                                    value={this.props?.songLibraryFilters?.showYr || ''}
                                    onChange={(e) => {
                                        this.handleFilterListSelection("showYr", "showYr", e.target.value, "showYr")
                                        this.props.updateListState(e.target.value, 'showYr', 'songLibraryFilters')
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={12}>
                                <SelectField
                                    id="episode"
                                    // label={"Seasons"}
                                    placeHolderText="- Episode Name -"
                                    options={this.props?.songLibraryFilters?.season ? this.state.filterList?.seasonBasedEpisodeList : this.state.filterList?.episodeOption || []}
                                    width="80%"
                                    value={this.props?.songLibraryFilters?.episodeName || null}
                                    onChange={(e) => {
                                        let selectedValue = (this.props?.songLibraryFilters?.season ? this.state.filterList?.seasonBasedEpisodeList : this.state.filterList?.episodeOption)?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'episodeName', 'songLibraryFilters')
                                        this.handleFilterListSelection('episodeName', selectedValue?.value, selectedValue?.label, 'episodeName')
                                    }}
                                />
                                {/* <BasicTextField
                                    id="Episodename"
                                    placeholder="Episode Name"
                                    value={this.props?.songLibraryFilters?.episodeName || ''}
                                    onChange={(e) => {
                                        this.handleFilterListSelection("episodeName", "episodeName", e.target.value, "episodeName")
                                        this.props.updateListState(e.target.value, 'episodeName', 'songLibraryFilters')
                                    }}
                                /> */}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={5}>
                                <BasicTextField
                                    id="Episode"
                                    placeholder="Episode"
                                    value={this.props?.songLibraryFilters?.episode || ''}
                                    onChange={(e) => {
                                        this.handleFilterListSelection("episode", "episode", e.target.value, "episode")
                                        this.props.updateListState(e.target.value, 'episode', 'songLibraryFilters')
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
})
