import React, { Component } from 'react';
import { MDBContainer, MDBRow } from "mdbreact";
import TableComponent from '../../SharedComponents/Table'
import { agentListConfig } from '../Licensor/Config'
import TablePagination from '@material-ui/core/TablePagination';
import { handlePaginationList, updateTableSortConfigObject, tableSortList } from '../../../Common/TableHelper';
import './Licensor.scss';
import { validateEmail, validateAllPhone } from '../../../Common/Helper';

class LicensorContacts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: 20,
            page: 0,
            renderList: handlePaginationList(0, 20, this.props?.licensorData?.licensor_contacts), //this.props?.licensorData.licensor_contacts,
            licensorData: this.props?.licensorData,
            currentList: this.props?.licensorData?.licensor_contacts,
            config: agentListConfig,
            isSortByAsc: true,
            sortCount: 0,
            sortBy: "Value"
        }
    }
    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage, renderList: handlePaginationList(newPage, this.state.rowsPerPage, this.state.currentList) });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10), page: 0,
            renderList: handlePaginationList(0, parseInt(event.target.value, 10), this.state.currentList)
        });
    }
    handleAddNewItem = (newDataItem) => {
        if((newDataItem?.email ? validateEmail(newDataItem?.email) : true) && 
        (newDataItem?.phone ? validateAllPhone(newDataItem?.phone) : true) && 
        (newDataItem?.other_phone ? validateAllPhone(newDataItem?.other_phone) : true)) {
        let allDetails = this.state?.renderList?.length > 0 ? [...this.state.renderList] : []; //
        let licensorData = this.state.licensorData
        let addList = {
            assistant_name: newDataItem.assistant_name || "",
            email: newDataItem.email || "",
            full_name: newDataItem.full_name || "",
            is_cc: newDataItem.is_cc ? 1 : 0,
            is_cl_contact: newDataItem.is_cl_contact ? 1 : 0,
            is_cre_contact: newDataItem.is_cre_contact ? 1 : 0,
            is_primary: newDataItem.is_primary ? 1 : 0,  
            lc_is_active: newDataItem.is_inactive ? 0 : 1,
            is_inactive:newDataItem.is_inactive ? 1 : 0,
            other_phone: newDataItem.other_phone || "",
            phone: newDataItem.phone || "",
            licensor_contact_id : Date.now()+'new'
        }
        let index = this.state?.renderList?.length > 0 ? this.state?.renderList?.length : 0;
        allDetails[index] = addList
        licensorData.licensor_contacts = allDetails
        licensorData.is_contacts_updated = 1
        this.setState({ renderList: allDetails, licensorData: licensorData })
        this.props?.fieldChanged(true, "error")
    }}
    handleDeleteItem = (dataItem) => {
        let allDetails = this.state.renderList
        let licensorData = this.state.licensorData
        licensorData.is_contacts_updated = 1
        let selectedIndex = allDetails.findIndex(item => item.licensor_contact_id === dataItem)
        let selectedRow = allDetails.find(item => item.licensor_contact_id === dataItem)
        allDetails.splice(selectedIndex, 1)
        if(!isNaN(dataItem)) {
            selectedRow.is_delete = 1
            this.props.updateDeletedContacts(selectedRow)
        }
        licensorData.licensor_contacts = allDetails
        this.setState({ renderList: allDetails, licensorData: licensorData })
        this.props?.fieldChanged(true, "error")
    }
    handleReadCallback = (index) => {
        let allDetails = [...this.state.renderList]
        allDetails[index].editing = false
        this.setState({ renderList: allDetails })
    }
    handleInlineEdits = (id, dataItem) => {
        let allDetails = [...this.state.renderList]
        this.setState({
            renderList: allDetails.map((item) => {
                let newItem = {...item}
                if (item.licensor_contact_id == dataItem.licensor_contact_id)
                    newItem['editing'] = true
                return newItem
            })
        })
        this.props?.fieldChanged(true, "error")
    }
    saveInlineEdits = (data, index, id, dataItem) => {
        if((data[index]?.email ? validateEmail(data[index]?.email) : true) && 
        (data[index]?.phone ? validateAllPhone(data[index]?.phone) : true) && 
        (data[index]?.other_phone ? validateAllPhone(data[index]?.other_phone) : true) ) {
        let allDetails = [...this.state.renderList]
        let licensorData = this.state.licensorData
        let selectedContactList = data.filter(item => item.licensor_contact_id == dataItem.licensor_contact_id)
        this.setState({
            renderList: allDetails.map((item) => {
                if (item.licensor_contact_id == dataItem.licensor_contact_id) {
                    item['editing'] = false,
                        item.assistant_name = selectedContactList[0].assistant_name,
                        item.email = selectedContactList[0].email,
                        item.full_name = selectedContactList[0].full_name,
                        item.is_cc = selectedContactList[0].is_cc ? 1 : 0,
                        item.is_cl_contact = selectedContactList[0].is_cl_contact ? 1 : 0,
                        item.is_cre_contact = selectedContactList[0].is_cre_contact ? 1 : 0,
                        item.is_primary = selectedContactList[0].is_primary ? 1 : 0,
                        item.lc_is_active = selectedContactList[0].is_inactive == 1 ? 0 : 1,                       
                        item.is_inactive = selectedContactList[0].is_inactive ? 1 : 0,
                        item.other_phone = selectedContactList[0].other_phone,
                        item.phone = selectedContactList[0].phone
                }
                return item
            })
        })
        licensorData.licensor_contacts = this.state.renderList
        licensorData.is_contacts_updated = 1
        this.setState({ licensorData: licensorData })
        this.props?.fieldChanged(true, "error")
        }
    }
    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }
    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(agentListConfig, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            this.setState({
                sortBy: colName,
                renderList: handlePaginationList(this.state.page, this.state.rowsPerPage,
                    tableSortList("Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc))
            });
        })
    }
    getSortNode = (col) => {
        switch (col) {
            case "FullName": return "full_name";
            case "Email": return "email";
            case "Phone": return "phone";
            case "OtherPhone": return "other_phone";
            case "Assistant": return "assistant_name";
        }
    }

    render() {
        return (
            <MDBContainer fluid className="ModalTableContainer" disabled={this.props?.disabled}>
                <MDBRow className='g-0 licensorCLRecords' id="licensor-scroll">
                    <TableComponent
                        list={this.state.renderList || []}
                        className={"agentList"}
                        config={this.state.config}
                        editCallback={this.handleInlineEdits.bind(this)}
                        saveCallback={this.saveInlineEdits.bind(this)}
                        isLoading={this.props.isLoading || false}
                        addItemToList={true}
                        handleAddNewItem={this.handleAddNewItem.bind(this)}
                        handleDelCallBack={this.handleDeleteItem.bind(this)}
                        readCallback={this.handleReadCallback.bind(this)}
                        sortCallback={this.handleTableColumnClick}
                    />
                </MDBRow>
                <MDBRow className="Pagination">
                    <TablePagination
                        component="div"
                        count={this.state.renderList ? this.props?.licensorData.licensor_contacts.length : 0}
                        rowsPerPageOptions={[20, 30, 50, 100]}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        rowsPerPage={this.state.rowsPerPage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </MDBRow>
            </MDBContainer>
        );
    }
}

export default LicensorContacts;